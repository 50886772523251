import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApplicationService } from '../../services/application.service';
import { LoginService } from '../../services/login.service';
import { IframeService } from '../../services/iframe.service';
import { ConfigService } from '../../services/config.service';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-icon-mobile',
    templateUrl: './icon-mobile.component.html',
    styleUrls: ['./icon-mobile.component.scss']
})
export class IconMobileComponent implements OnInit {
    centerIcons: any;
    numberNewsUnseen: number;
    newsLoaded: boolean;
    iconsLoaded$: Subscription;
    helpIsEnabled: boolean;

    constructor(
        private applicationService: ApplicationService,
        private iframeService: IframeService,
        private loginService: LoginService,
        private configService: ConfigService
    ) {}

    isOpened() {
        return this.iframeService.isOpenedComponent('IconMobileComponent');
    }

    /**
     *  poste a l'application le message d'aller sur une route.
     * @returns {void}
     */
    goTo(route: string): void {
        if (route === 'newsList') {
            this.iframeService.openComponent('NewsComponent');
        } else {
            window.parent.postMessage(route, '*');
        }
        return this.iframeService.closeComponent('IconMobileComponent');
    }

    openHelp(): void {
        if (this.iframeService.isOpenedComponent('HelpComponent')) {
            this.iframeService.closeComponent('HelpComponent');
        } else {
            this.iframeService.openComponent('HelpComponent');
            return this.iframeService.closeComponent('IconMobileComponent');
        }
    }

    /**
     * @returns {string} la couleur de l'application courante.
     */
    getApplicationColor(): string {
        const currentApp = this.applicationService.getCurrentApplication();

        if (currentApp && currentApp.color) {
            return currentApp.color;
        } else {
            return '#424242';
        }
    }

    ngOnInit() {
        this.numberNewsUnseen = 0;
        this.newsLoaded = false;
        this.helpIsEnabled = true;
        this.iconsLoaded$ = this.loginService.iconsLoaded.subscribe(() => {
            this.centerIcons = this.loginService.getCenterIconsForCurrentUser();
            this.helpIsEnabled =
                this.loginService.getHelpState() && this.configService.getConfig().helpVisible;
        });
    }
}
