import { Injectable } from '@angular/core';

import { LogService } from './log.service';

/**
 * Service gérant l'affichage des messages de succès/erreur lors des appels aux webservice
 */
@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    constructor(private logService: LogService) {}

    vues: Object = {};

    startLoading(vue: string, action?: string) {
        if (!this.vues[vue]) {
            this.vues[vue] = {};
        }
        if (action) {
            this.vues[vue][action] = true;
        } else {
            this.vues[vue].general = true;
        }
    }

    stopLoading(vue: string, action?: string) {
        if (this.vues[vue]) {
            if (action) {
                if (this.vues[vue][action]) {
                    this.vues[vue][action] = false;
                } else {
                    this.logService.warn(
                        'LoadingService : stopLoading',
                        `L'action '${action}' n'existe pas dans la vue '${vue}'`
                    );
                }
            } else {
                this.vues[vue] = {};
            }
        } else {
            this.logService.warn('LoadingService : stopLoading', `La vue '${vue}' n'existe pas`);
        }
    }

    isLoading(vue: string, action?: string) {
        if (this.vues[vue]) {
            if (action) {
                if (this.vues[vue][action]) {
                    return this.vues[vue][action] === true;
                } else {
                    return false;
                }
            } else {
                for (const property in this.vues[vue]) {
                    if (this.vues[vue][property] === true) {
                        return true;
                    }
                }
                return false;
            }
        }
        return false;
    }

    isSomethingLoading(): boolean {
        return Object.keys(this.vues).length === 0;
    }

    logLoading() {
        this.logService.success('LoadingService : logLoading', JSON.stringify(this.vues));
    }
}
