<div class="application-list" [ngClass]="{ opened: isOpened() }">
    <div class="section-container" *ngFor="let section of sections">
        <div class="title-container">
            <span class="title-text">{{ section }}</span>
        </div>
        <div
            class="application-container"
            *ngFor="let app of applications | appFilterPipe: section"
        >
            <div
                class="application-entry"
                [ngClass]="{ current: isActiveApp(app) }"
                [ngStyle]="{ color: app.color }"
            >
                <div class="header" (click)="goToURL(app)">
                    <span class="icon {{ app.icon_font }}" *ngIf="app.icon_font"></span>
                    <img
                        [src]="'data:image/png;base64,' + app.icon_png"
                        class="icon"
                        *ngIf="!app.icon_font && app.icon_png"
                    />
                    <div class="text">{{ app.name }}</div>
                    <span
                        class="icon icon-multi-ecran-bis"
                        (click)="goToTab($event, app)"
                        [tooltip]="'Ouvrir dans un nouvel onglet'"
                        tooltipColor="#fff"
                        [tooltipTextColor]="app.color"
                        *ngIf="!app.children"
                    ></span>
                    <div class="icon-down" *ngIf="app.children"></div>
                </div>

                <div class="application-children" *ngIf="app.children">
                    <div *ngFor="let child of app.children" class="header" (click)="goToURL(child)">
                        <span class="icon {{ child.icon_font }}" *ngIf="child.icon_font"></span>
                        <div class="text">{{ getChildName(child) }}</div>
                        <span
                            class="icon icon-multi-ecran-bis"
                            (click)="goToTab($event, child)"
                            [tooltip]="'Ouvrir dans un nouvel onglet'"
                            tooltipColor="#fff"
                            [tooltipTextColor]="app.color"
                        ></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="separator"></div>
    </div>
</div>
