<div id="help-container" [ngClass]="getClasses()" [ngStyle]="getStyles()">
    <div class="search-container">
        <input
            type="text"
            class="search-field"
            [(ngModel)]="searchTerm"
            placeholder="Rechercher..."
            (keyup.enter)="filterData()"
        />
        <span class="icon-search" (click)="filterData()"></span>
        <span class="icon-Fermerdetails" (click)="resetSearch()" *ngIf="searchTerm !== ''"></span>
    </div>
    <span class="icon-close" (click)="close()"></span>

    <div class="contact-container">
        <ng-container *ngIf="showAssistance">
            <div class="section-title">Assistance</div>
            <ng-container *ngFor="let contact of contacts">
                <div class="contact" *ngIf="contact.structureid">
                    <div
                        class="assistance"
                        [innerHTML]="contact.assistance_card"
                        [ngClass]="{ expended: !contact.display_contact_card }"
                    ></div>
                    <div class="contact-element" *ngIf="contact.display_contact_card">
                        <div class="avatar">
                            <img [src]="contact.avatar" *ngIf="contact.avatar" />
                            <div *ngIf="!contact.avatar" class="icon-AdminActivite"></div>
                        </div>
                        <div class="contact-info" [innerHTML]="contact.contact_card"></div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="showNationalAssistance">
            <div class="section-title">Assistance nationale</div>
            <ng-container *ngFor="let contact of contacts">
                <div class="contact" *ngIf="!contact.structureid">
                    <div
                        class="assistance"
                        [innerHTML]="contact.assistance_card"
                        [ngClass]="{ expended: !contact.display_contact_card }"
                    ></div>
                    <div class="contact-element" *ngIf="contact.display_contact_card">
                        <div class="avatar">
                            <img [src]="contact.avatar" *ngIf="contact.avatar" />
                            <div *ngIf="!contact.avatar" class="icon-AdminActivite"></div>
                        </div>
                        <div class="contact-info" [innerHTML]="contact.contact_card"></div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="helpData">
        <!-- SECTIONS -->
        <ng-container *ngFor="let section of filteredHelpData.sections">
            <div class="section" *ngIf="canShowElementOfData(section)">
                <div class="section-title" *ngIf="section">{{ section.section_title }}</div>

                <!-- SOUS SECTIONS -->
                <div class="subsections" *ngIf="section && section.subsections">
                    <ng-container *ngFor="let subsection of section?.subsections">
                        <div class="subsection" *ngIf="canShowElementOfData(subsection)">
                            <div class="subsection-title" *ngIf="subsection">
                                {{ subsection.subsection_title }}
                            </div>
                            <!-- VIDEOS DE SOUS SECTION -->
                            <div
                                class="videos"
                                *ngIf="subsection?.video_items"
                                [ngClass]="{
                                    'one-column': subsection.video_items.length === 1,
                                    'two-columns': subsection.video_items.length === 2
                                }"
                            >
                                <ng-container *ngFor="let video of subsection.video_items">
                                    <div class="video-card" *ngIf="canShowElementOfData(video)">
                                        <div
                                            class="video-card__preview"
                                            (click)="openVideoDialog(video)"
                                        >
                                            <img [src]="video.img" *ngIf="video.img" />
                                            <div class="icon-background"></div>
                                            <div class="icon-Video"></div>
                                            <div class="overlay-mask"></div>
                                        </div>
                                        <div class="video-card__name">{{ video.name }}</div>
                                    </div>
                                </ng-container>
                            </div>

                            <div
                                class="contents"
                                *ngIf="subsection?.easi_items"
                                [ngClass]="{
                                    'one-column': subsection.easi_items.length === 1,
                                    'two-columns': subsection.easi_items.length === 2
                                }"
                            >
                                <ng-container *ngFor="let content of subsection.easi_items">
                                    <div
                                        class="content-card"
                                        *ngIf="
                                            getContentFromID(content) &&
                                            canShowElementOfData(content)
                                        "
                                    >
                                        <div
                                            class="content-card-preview"
                                            (click)="openContentDialog(getContentFromID(content))"
                                        >
                                            <img [src]="content.img" *ngIf="content.img" />
                                            <div class="icon-background"></div>
                                            <div class="icon-Video"></div>
                                            <div class="overlay-mask"></div>
                                        </div>
                                        <div class="content-card-name">
                                            <div class="title">
                                                {{ getContentFromID(content).title }}
                                                <div
                                                    class="icon-voir-details"
                                                    [tooltip]="
                                                        getContentFromID(content).description
                                                    "
                                                ></div>
                                            </div>

                                            <div class="time">
                                                <div class="icon-Timer-cercle"></div>
                                                <div>{{ getContentFromID(content).duration }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <!-- IMAGES DE SOUS SECTION -->
                            <div class="images" *ngIf="subsection?.img_items">
                                <div class="image-card" *ngFor="let image of subsection.img_items">
                                    <img [src]="image.url" />
                                </div>
                            </div>

                            <!-- QUESTIONS FREQUEMMENT POSEES DE SOUS SECTION -->
                            <div class="questions" *ngIf="subsection?.question_items">
                                <ng-container *ngFor="let question of subsection.question_items">
                                    <div
                                        class="question-card"
                                        *ngIf="canShowElementOfData(question)"
                                    >
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>{{
                                                    question.question_title
                                                }}</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div
                                                class="question-card__content"
                                                [innerHTML]="question.question_test"
                                            ></div>
                                        </mat-expansion-panel>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- VIDEOS DE SECTION -->
                <div
                    class="videos"
                    *ngIf="section?.video_items"
                    [ngClass]="{
                        'one-column': section.video_items.length === 1,
                        'two-columns': section.video_items.length === 2
                    }"
                >
                    <ng-container *ngFor="let video of section.video_items">
                        <div class="video-card" *ngIf="canShowElementOfData(video)">
                            <div class="video-card__preview" (click)="openVideoDialog(video)">
                                <img [src]="video.img" *ngIf="video.img" />
                                <div class="icon-background"></div>
                                <div class="icon-Video"></div>
                                <div class="overlay-mask"></div>
                            </div>
                            <div class="video-card__name">{{ video.name }}</div>
                        </div>
                    </ng-container>
                </div>

                <!-- IMAGES DE SECTION -->
                <div class="images" *ngIf="section?.img_items">
                    <div class="image-card" *ngFor="let image of section.img_items">
                        <img [src]="image.url" />
                    </div>
                </div>

                <!-- QUESTIONS FREQUEMMENT POSEES DE SECTION -->
                <div class="questions" *ngIf="section?.question_items">
                    <ng-container *ngFor="let question of section.question_items">
                        <div class="question-card" *ngIf="canShowElementOfData(question)">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>{{ question.question_title }}</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div
                                    class="question-card__content"
                                    [innerHTML]="question.question_test | safeHtml"
                                ></div>
                            </mat-expansion-panel>
                        </div>
                    </ng-container>
                </div>

                <div
                    class="contents"
                    *ngIf="section?.easi_items"
                    [ngClass]="{
                        'one-column': section.easi_items.length === 1,
                        'two-columns': section.easi_items.length === 2
                    }"
                >
                    <ng-container *ngFor="let content of section.easi_items">
                        <div
                            class="content-card"
                            *ngIf="getContentFromID(content) && canShowElementOfData(content)"
                        >
                            <div
                                class="content-card-preview"
                                (click)="openContentDialog(getContentFromID(content))"
                            >
                                <img [src]="getContentImg(content)" />
                                <div class="icon-background"></div>
                                <div class="icon-Video"></div>
                                <div class="overlay-mask"></div>
                            </div>
                            <div class="content-card-name">
                                <div class="title">
                                    {{ getContentFromID(content).title }}
                                    <div
                                        class="icon-voir-details"
                                        [tooltip]="getContentFromID(content).description"
                                    ></div>
                                </div>

                                <div class="time">
                                    <div class="icon-Timer-cercle"></div>
                                    <div>{{ getContentFromID(content).duration }}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
