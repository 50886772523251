<div
    class="user-profil"
    [ngClass]="{ opened: isOpened() }"
    [ngStyle]="{ 'background-color': getApplicationColor() }"
>
    <div class="info-user">
        <div class="name-container">
            <span class="icon-userinconnu" *ngIf="!avatar"></span>
            <span class="avatar" *ngIf="avatar">
                <img [src]="avatar" />
            </span>
            <div class="fullname">{{ fullName }}</div>
        </div>
        <div class="structure-container">
            <span [ngClass]="getStructureIcon()"></span>
            <div class="structure">{{ getStructureName() }}</div>
        </div>
        <div class="roles-container">
            <div *ngFor="let role of roles">
                <span class="icon {{ role.icon }}"></span>

                <div class="role">{{ role.name }}</div>
            </div>

            <div *ngFor="let role of additionalRoles">
                <span class="icon {{ role.icon }}"></span>

                <div class="role">{{ role.name }}</div>
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="login-user">
        <div class="username-container">
            <div class="label">Identifiant</div>
            <div class="username">{{ username }}</div>
        </div>
        <div class="email-container">
            <div class="label">Adresse email</div>
            <div class="email">{{ email }}</div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="footer">
        <div class="update-email-container" (click)="updateEmail()" *ngIf="canChangeMyEmail()">
            <span class="icon-message"></span>
            <span class="label">Modifier mon adresse e-mail</span>
        </div>
        <div class="update-photo-container" (click)="updatePhoto()">
            <span class="icon-userinconnu"></span>
            <span class="label">Modifier la photo de mon profil</span>
        </div>
        <div class="update-password-container" (click)="updatePassword()">
            <span class="icon-Reinitialiserlemotdepasse"></span>
            <span class="label">Modifier mon mot de passe</span>
        </div>
        <div
            class="manage-notifications-container"
            (click)="manageNotifications()"
            *ngIf="canManageNotifications()"
        >
            <span class="icon-reglages"></span>
            <span class="label">Gérer mes notifications</span>
        </div>
        <div class="manage-token-container" (click)="manageToken()" *ngIf="showTokenManager()">
            <span class="icon-cadenas"></span>
            <span class="label">Gérer mes tokens de connexions</span>
        </div>
        <div
            class="manage-mobile-container"
            (click)="manageMobileApp()"
            *ngIf="showMobileAppManager()"
        >
            <span class="icon-qrcode"></span>
            <span class="label">Me connecter à EASi notification</span>
        </div>
        <div class="manage-azure-container" (click)="manageAzure()" *ngIf="showAzureContainer()">
            <span class="icon-Office365"></span>
            <span class="label">{{ getAzureLabel() }}</span>
        </div>
        <div
            class="logout-container"
            (click)="logout()"
            [ngStyle]="{ color: getApplicationColor() }"
            *ngIf="!loggedOut"
        >
            <span class="icon-unlog"></span>
            <span class="label">Me déconnecter</span>
        </div>
        <div class="logout-container" *ngIf="loggedOut">
            <div class="loadingEntry">
                <div
                    class="point1 point"
                    [ngStyle]="{ 'background-color': getApplicationColor() }"
                ></div>
                <div
                    class="point2 point"
                    [ngStyle]="{ 'background-color': getApplicationColor() }"
                ></div>
                <div
                    class="point3 point"
                    [ngStyle]="{ 'background-color': getApplicationColor() }"
                ></div>
            </div>
        </div>
    </div>
</div>

<iframe [src]="url" *ngFor="let url of logoutUrl" style="display: none"></iframe>
