import { Component, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concat, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EasiHttpParams } from '../../interceptors/easi-http-params';

import { ConfigService } from '../../services/config.service';
import { ApplicationService } from '../../services/application.service';
import { LoginService } from '../../services/login.service';

/**
 *  fenetre de dialogue pour la deconnexion.
 */
@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
    constructor(
        private configService: ConfigService,
        private applicationService: ApplicationService,
        private loginService: LoginService,
        private http: HttpClient
    ) {}

    logoutURLs = [];
    showRedirectButton = false;

    ngOnInit() {
        this.loginService.logout().subscribe((data) => {
            this.logoutURLs = data.frontend_logout_urls.map((url: string) => {
                return {
                    value: url,
                    status: 0
                };
            });

            const calls = this.logoutURLs.map((url) => {
                return this.http
                    .get(url.value, {
                        params: new EasiHttpParams({
                            overrideBaseUrl: true,
                            noError: true
                        }),
                        responseType: undefined
                    })
                    .pipe(
                        map(() => {
                            url.status = 1;
                        }),
                        catchError((error) => {
                            if (error.status >= 200 && error.status < 400) {
                                url.status = 1;
                            } else {
                                url.status = -1;
                            }
                            return EMPTY;
                        })
                    );
            });
            concat(...calls).subscribe({
                next: () => {},
                error: () => {},
                complete: () => {
                    this.showRedirectButton = true;
                }
            });
        });
    }

    getCleanURL(url: string) {
        return url.split('.fr')[0] + '.fr';
    }

    /**
     * @returns {string} la couleur de l'application courante.
     */
    getApplicationColor(): string {
        const currentApp = this.applicationService.getCurrentApplication();

        if (currentApp && currentApp.color) {
            return currentApp.color;
        } else {
            return '#424242';
        }
    }

    /**
     *  redirige l'utilisateur sur la page de login.
     */
    redirectUser(): void {
        window.top.location.href = this.configService.getConnectFrontEndPoint() + '/#/login';
        window.top.location.reload();
    }
}
