import { Injectable } from '@angular/core';
import { LogService } from './log.service';

/**
 *  service qui gere l'ouverture ou la fermeture de l'iframe
 */
@Injectable({
    providedIn: 'root'
})
export class IframeService {
    openedComponent: Array<string>;

    constructor(private logService: LogService) {
        window.addEventListener('deviceorientation', () => this.checkIframe, true);
        this.openedComponent = [];
    }

    toggleComponent(component: string): void {
        if (!this.isOpenedComponent(component)) {
            this.openComponent(component);
        } else {
            this.closeComponent(component);
        }
    }

    openComponent(component: string): void {
        if (component === 'ApplicationComponent') {
            if (this.isOpenedComponent('NotificationComponent')) {
                this.closeComponent('NotificationComponent');
                setTimeout(() => {
                    this.openedComponent.push(component);
                    this.checkIframe();
                }, 500);
            } else {
                this.openedComponent.push(component);
                this.checkIframe();
            }
        } else if (component === 'NotificationComponent') {
            if (this.isOpenedComponent('ApplicationComponent')) {
                this.closeComponent('ApplicationComponent');
                setTimeout(() => {
                    this.openedComponent.push(component);
                    this.checkIframe();
                }, 500);
            } else {
                this.openedComponent.push(component);
                this.checkIframe();
            }
        } else {
            this.openedComponent.push(component);
            this.checkIframe();
        }
    }

    closeComponent(component: string): void {
        if (this.openedComponent.indexOf(component) > -1) {
            this.openedComponent.splice(this.openedComponent.indexOf(component), 1);
            this.checkIframe();
        }
    }

    closeAllComponent(): void {
        this.openedComponent = [];
        setTimeout(() => {
            this.reduceIframe();
        }, 300);
    }

    isOpenedComponent(component: string): boolean {
        return this.openedComponent.indexOf(component) > -1;
    }

    checkIframe(): void {
        if (
            this.isOpenedComponent('TokenManagerComponent') ||
            this.isOpenedComponent('MobileAppComponent') ||
            this.isOpenedComponent('UserComponent') ||
            this.isOpenedComponent('ApplicationComponent') ||
            this.isOpenedComponent('NotificationComponent') ||
            this.isOpenedComponent('IconComponent') ||
            this.isOpenedComponent('NewsComponent') ||
            this.isOpenedComponent('UnreadNewsComponent') ||
            this.isOpenedComponent('ErrorComponent') ||
            this.isOpenedComponent('WarningComponent') ||
            this.isOpenedComponent('LogoutComponent') ||
            this.isOpenedComponent('LoginTimeoutComponent') ||
            this.isOpenedComponent('ChangePhotoComponent') ||
            this.isOpenedComponent('HelpComponent') ||
            this.isOpenedComponent('UserDetailsComponent') ||
            this.isOpenedComponent('UserHistoryComponent') ||
            this.isOpenedComponent('NotificationsManagerComponent') ||
            this.isOpenedComponent('CallComponent') ||
            this.isOpenedComponent('ConfirmationDeleteUserComponent')
        ) {
            this.extendIframe();
        } else if (this.isOpenedComponent('IconMobileComponent')) {
            this.extendIframeMobile();
        } else if (
            this.isOpenedComponent('ChatPopupComponent') ||
            this.isOpenedComponent('NotificationPopupComponent')
        ) {
            this.extendIframePopup();
        } else {
            setTimeout(() => {
                this.reduceIframe();
            }, 300);
        }

        if (new URLSearchParams(window.location.search).get('chatOnly')) {
            if (this.isOpenedComponent('ChatComponent')) {
                this.extendLowerIframe();
            } else {
                this.reduceLowerIframe();
            }
        }
    }

    /**
     *  envoyer l'instruction a la plateforme d'etendre l'iframe.
     * @returns {void}
     */
    extendIframe(): void {
        window.parent.postMessage('extendIframe', '*');
    }

    /**
     *  envoyer l'instruction a la plateforme d'etendre l'iframe.
     * @returns {void}
     */
    extendLowerIframe(): void {
        window.parent.postMessage('extendLowerIframe', '*');
    }

    /**
     *  envoyer l'instruction a la plateforme d'etendre l'iframe pour la taille d'une popup.
     * @returns {void}
     */
    extendIframePopup(): void {
        window.parent.postMessage('extendIframePopup', '*');
    }

    /**
     *  envoyer l'instruction a la plateforme d'etendre l'iframe pour la taille d'une popup.
     * @returns {void}
     */
    extendIframeMobile(): void {
        window.parent.postMessage('extendIframeMobile', '*');
    }

    /**
     *  envoyer l'instruction a la plateforme de reduire l'iframe.
     * @returns {void}
     */
    reduceIframe(): void {
        window.parent.postMessage('reduceIframe', '*');
    }

    /**
     *  envoyer l'instruction a la plateforme de reduire l'iframe.
     * @returns {void}
     */
    reduceLowerIframe(): void {
        window.parent.postMessage('reduceLowerIframe', '*');
    }
}
