import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.scss']
})
export class WarningComponent {
    constructor(
        public dialogRef: MatDialogRef<WarningComponent>,
        @Inject(MAT_DIALOG_DATA) private data: string
    ) {}

    /**
     * Détermine le message à afficher dans le bandeau
     * @returns {String} Le message à afficher dans le bandeau
     */
    getHeader(): string {
        return 'Avertissement';
    }

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): string {
        return this.data;
    }

    /**
     * Détermine le message à afficher dans le bouton
     * @returns {String} Le message à afficher dans le bouton
     */
    getFooter(): string {
        return 'OK';
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog(status: boolean) {
        this.dialogRef.close(status);
    }
}
