<div id="notifications-manager-container" [ngClass]="getComponentClasses()">
    <div class="notifications-manager_header">
        <div class="width-wrapper">
            <div class="title-container">
                <span class="icon-notifications"></span>
                <span class="title">Gestion de vos notifications</span>
                <span class="icon-close" (click)="close()"></span>
            </div>
            <div class="grid-container">
                <div class="entry-title">Évènement pour lequel vous souhaitez être notifié</div>
                <div>Dans <strong>EASi</strong></div>
                <div>Dans <strong>EASi notification</strong></div>
                <div>
                    <strong>Email</strong><br />
                    immédiat
                </div>
                <div>
                    <strong>Email</strong><br />
                    quotidien
                </div>
                <div>
                    <strong>Email</strong><br />
                    hebdomadaire
                </div>
            </div>
        </div>
    </div>

    <div class="notifications-manager-content">
        <div class="width-wrapper">
            <!-- DYNAMIC -->
            <mat-expansion-panel
                class="card"
                ngClass="{{ application.app_id }}"
                [style.color]="getApplicationColor(application)"
                *ngFor="let application of myChannelsSettings"
            >
                <mat-expansion-panel-header>
                    <div class="grid-container">
                        <div class="entry-title main">
                            <span
                                [ngClass]="getAppIcon(application)"
                                *ngIf="getAppIcon(application)"
                            ></span>
                            <img
                                [src]="'data:image/png;base64,' + getAppImage(application)"
                                class="icon"
                                *ngIf="!getAppIcon(application) && getAppImage(application)"
                            />
                            <strong>{{ getAppName(application) }}</strong>
                            <div
                                class="note"
                                *ngIf="shouldDisplayAppNote(application)"
                                [innerHTML]="getAppNote(application)"
                            ></div>
                        </div>
                        <div
                            class="selector easi"
                            (click)="toggleAllWebNotifications($event, application)"
                        >
                            <div
                                [ngClass]="getApplicationViaWebSelectorIcon(application)"
                                *ngIf="application.app_id !== 'easinews'"
                            ></div>
                        </div>
                        <div
                            class="selector mobile"
                            (click)="toggleAllMobileNotifications($event, application)"
                        >
                            <div
                                [ngClass]="getApplicationViaMobileSelectorIcon(application)"
                                *ngIf="application.app_id !== 'easinews'"
                            ></div>
                        </div>
                        <div
                            class="selector immediate"
                            (click)="toggleAllMailNotifications($event, application, 'immediate')"
                        >
                            <div
                                [ngClass]="
                                    getApplicationViaMailSelectorIcon(application, 'immediate')
                                "
                            ></div>
                        </div>
                        <div
                            class="selector daily"
                            (click)="toggleAllMailNotifications($event, application, 'daily')"
                        >
                            <div
                                [ngClass]="getApplicationViaMailSelectorIcon(application, 'daily')"
                            ></div>
                        </div>
                        <div
                            class="selector weekly"
                            (click)="toggleAllMailNotifications($event, application, 'weekly')"
                        >
                            <div
                                [ngClass]="getApplicationViaMailSelectorIcon(application, 'weekly')"
                            ></div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="grid-container">
                    <ng-container *ngFor="let channel of application.channels">
                        <div class="entry-title">
                            {{ channel.name }}
                        </div>
                        <div
                            class="selector easi"
                            (click)="toggleWebNotificationsSelector(channel)"
                            *ngIf="application.app_id !== 'easinews'"
                        >
                            <div
                                [ngClass]="
                                    channel.user_settings.via_web === 'immediate'
                                        ? 'icon-selected'
                                        : 'icon-select'
                                "
                            ></div>
                        </div>
                        <div
                            class="selector mobile"
                            (click)="toggleMobileNotificationsSelector(channel)"
                            *ngIf="application.app_id !== 'easinews'"
                        >
                            <div
                                [ngClass]="
                                    channel.user_settings.via_mobile === 'immediate'
                                        ? 'icon-selected'
                                        : 'icon-select'
                                "
                            ></div>
                        </div>
                        <div
                            class="selector immediate"
                            (click)="setMailChannel(channel, 'immediate')"
                        >
                            <div
                                [ngClass]="
                                    channel.user_settings.via_mail === 'immediate'
                                        ? 'icon-selected'
                                        : 'icon-select'
                                "
                            ></div>
                        </div>
                        <div class="selector daily" (click)="setMailChannel(channel, 'daily')">
                            <div
                                [ngClass]="
                                    channel.user_settings.via_mail === 'daily'
                                        ? 'icon-selected'
                                        : 'icon-select'
                                "
                            ></div>
                        </div>
                        <div class="selector weekly" (click)="setMailChannel(channel, 'weekly')">
                            <div
                                [ngClass]="
                                    channel.user_settings.via_mail === 'weekly'
                                        ? 'icon-selected'
                                        : 'icon-select'
                                "
                            ></div>
                        </div>
                    </ng-container>
                </div>
            </mat-expansion-panel>
        </div>
    </div>

    <div class="notifications-manager-footer">
        <div class="width-wrapper">
            <div class="save-button" (click)="saveSettings()">Enregistrer les modifications</div>
        </div>
    </div>
</div>
