<div class="header" [ngStyle]="{ 'background-color': getApplicationColor() }">
    <span class="icon-unlog"></span>
    <span class="label">Déconnexion réussie</span>
</div>
<mat-dialog-content class="content">
    Déconnexion en cours :
    <ul>
        <li *ngFor="let url of logoutURLs">
            <div class="url">
                {{ getCleanURL(url.value) }}
            </div>
            <span *ngIf="url.status === 0">...</span>
            <span class="icon-Valide" *ngIf="url.status === 1"></span>
            <span class="icon-Fermerdetails" *ngIf="url.status === -1"></span>
        </li>
    </ul>
</mat-dialog-content>
<div
    class="footer"
    [ngStyle]="{ 'background-color': getApplicationColor() }"
    *ngIf="showRedirectButton"
>
    <div
        class="logout-button"
        [ngStyle]="{ color: getApplicationColor() }"
        (click)="redirectUser()"
    >
        Retourner sur la page de connexion
    </div>
</div>
