<div class="header">
    <div class="left-header">
        <div class="icon" [ngClass]="[getRoleIcon(), getConnectionStatus()]"></div>
        <div class="user">
            <div class="name">{{ getUserFullname() }}</div>
            <div class="status">{{ getUserStatus() }}</div>
        </div>
    </div>
    <div class="right-header">
        <div
            class="icon-Messages"
            (click)="goToChat()"
            [tooltip]="'Poursuivre dans easi chat'"
        ></div>
        <div class="icon-close" (click)="close()"></div>
    </div>
</div>
<mat-dialog-content class="content" *ngIf="!isLoading()">
    <div
        class="main-container"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolledUp)="getPreviousMessages()"
    >
        <app-chat-entry
            *ngFor="let msg of messagesList; last as isLast"
            [message]="msg"
            [last]="isLast"
            [chat]="chat"
            (clickEditMessage)="editMessage($event)"
        >
        </app-chat-entry>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content" *ngIf="isLoading()">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="chat-footer">
    <div class="input-container">
        <div
            class="message-input"
            id="message-input"
            placeholder="Rédiger un message"
            [contentEditable]="!isSending && !isLoading()"
            (keydown.enter)="sendMessage($event)"
            [ngClass]="{ 'is-sending': isSending || isLoading() }"
            (paste)="parseHTML($event)"
        ></div>
        <span
            class="icon-Envoyer"
            [ngClass]="{ 'is-sending': isSending || isLoading() }"
            (click)="sendMessage($event)"
        ></span>
    </div>

    <div class="upload-container" (click)="uploadFile()">
        <div class="icon-Importer"></div>
    </div>
    <input
        type="file"
        #fileElement
        style="display: none"
        (change)="onFilesAdded($event.target.files)"
    />
    <div class="file-container" *ngIf="filesArray?.length > 0">
        <div class="attachment-container" *ngFor="let file of filesArray; index as i">
            <span class="name">{{ file.name }}</span>
            <span class="size">({{ getFormattedSize(file.size) }})</span>
            <span class="icon-close" (click)="removeAttachment(i)"></span>
        </div>
    </div>
</div>

<iframe [src]="frameUrl" style="display: none"></iframe>
