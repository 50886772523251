<div class="notif-container" [ngClass]="{ opened: isOpened() }">
    <div class="header">
        <div class="notif-button">
            <span class="icon icon-notifications"></span>
            <span class="nb-notif">{{ getNbNotification() }}</span>
        </div>

        <div class="text-notif">Notifications</div>

        <div class="right-container">
            <div class="cross icon-close" (click)="closeNotificationMenu()"></div>
            <div
                class="icon-reglages"
                (click)="manageNotifications()"
                *ngIf="canManageNotifications()"
            >
                <div
                    class="manage-notifications_tooltip"
                    [ngStyle]="{
                        'background-color': getApplicationColor(),
                        'border-color': getApplicationColor()
                    }"
                >
                    Gérer mes notifications
                </div>
            </div>
            <!-- <div class="glass icon-search"></div> -->
            <!-- <input [(ngModel)]="searchText" (keyup.enter)="researchNotification()" class="search-bar" placeholder="Rechercher..."> -->
        </div>
    </div>
    <div class="read-notif-button" *ngIf="hasUnreadNotifications()" (click)="markAllAsRead()">
        Tout marquer comme lu
    </div>
    <div
        class="read-notif-button"
        *ngIf="!hasUnreadNotifications() && hasNotifications()"
        (click)="deleteAllNotifications()"
    >
        Supprimer toutes les notifications
    </div>
    <div class="separator"></div>
    <div
        class="notification-scroller"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextNotification()"
    >
        <app-notification-entry
            *ngFor="let notification of notifications | notificationsFilterByApp: applications"
            [notification]="notification"
            (click)="selectNotification(notification.id)"
            [selected]="isSelectedNotification(notification.id)"
            (delNotif)="deleteNotification(notification.id)"
        ></app-notification-entry>
    </div>
</div>
