import {
    Component,
    NgModule,
    Inject,
    OnInit,
    AfterViewChecked,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { ConfigService } from '../../services/config.service';

import { LoadingService } from '../../services/loading.service';
import { ChatService } from '../../services/chat.service';

import { UntilDestroy } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../structures/user';

/**
 *  fenetre de dialogue pour la deconnexion.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewChecked {
    @ViewChild('fileElement', { static: true }) fileElement: ElementRef;
    file: File;
    filesArray: Array<any>;

    frameUrl: SafeResourceUrl;

    scroll = false;
    updateHeight = false;

    message: string;
    messagesList: Array<any> = [];

    isSending: boolean;

    discussionId: number;
    user: User;

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        private sanitizer: DomSanitizer,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private notificationService: NotificationService,
        private configService: ConfigService,
        private chatService: ChatService,
        private dialogRef: MatDialogRef<ChatComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        this.loadingService.startLoading('ChatComponent');

        this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

        const interval = setInterval(() => {
            this.chatService.getCurrentUserChat().subscribe(
                () => {
                    this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
                    clearInterval(interval);
                    this.initData();
                },
                (error: HttpErrorResponse) => {
                    this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                        this.chatService.getChatLoginEndPoint()
                    );
                }
            );
        }, 2000);

        this.filesArray = [];

        this.notificationService.connectionStatusChanged.subscribe((event) => {
            if (event.data.uniqueid === this.user.uniqueid) {
                this.user.connectionStatus = event.data.connectionstatus;
            }
        });
        this.notificationService.newChatReceived.subscribe(() => {
            this.chatService.getMessages(30, this.discussionId).subscribe((list) => {
                this.messagesList = list.reverse();
                setTimeout(() => {
                    this.scroll = true;
                });
            });
        });
    }

    ngAfterViewChecked() {
        if (this.scroll) {
            this.scrollToBottom();
            this.scroll = false;
        }
        if (this.updateHeight) {
            this.updateContainerHeight();
            this.updateHeight = false;
        }
    }

    initData() {
        let query;
        if (this.data.discussionid) {
            query = this.chatService.getDiscussion(this.data.discussionid);
        } else {
            query = this.chatService.initDiscussion(this.data);
        }
        query.subscribe((discussion: any) => {
            this.user = discussion.users.filter(
                (user: User) => user.id !== this.loginService.getUser().id
            )[0];
            this.discussionId = discussion.id;
            this.initMessages();
            setTimeout(() => {
                document.getElementById('message-input').focus();
            }, 200);
        });
    }

    initMessages() {
        this.chatService.getMessages(30, this.discussionId).subscribe((list) => {
            this.messagesList = list.reverse();
            setTimeout(() => {
                this.scroll = true;
            });
            this.isSending = false;
            this.loadingService.stopLoading('ChatComponent');
        });
    }

    isLoading() {
        return this.loadingService.isLoading('ChatComponent');
    }

    getConnectionStatus() {
        if (this.user) {
            if (this.user.connectionStatus) {
                return this.user.connectionStatus;
            } else {
                return 'online';
            }
        }
        return '';
    }

    getRoleIcon() {
        if (this.user) {
            if (this.user.roles.prospect) {
                return 'icon-apprenant-prospect';
            } else if (this.user.roles.learner) {
                return 'icon-apprenant';
            } else if (this.user.roles.internalTeacher) {
                return 'icon-formateurinterne';
            } else if (this.user.roles.externalTeacher) {
                return 'icon-formateurexterne';
            } else if (this.user.roles.siteTeacher) {
                return 'icon-formateur-site';
            } else if (this.user.roles.corporationTeacher) {
                return 'icon-FormateurEntreprise';
            } else if (this.user.roles.tutor) {
                return 'icon-tuteurentreprise';
            } else if (this.user.roles.localAdmin) {
                return 'icon-adminlocal';
            } else if (this.user.roles.nationalAdmin) {
                return 'icon-adminnational';
            }
        }
        return '';
    }

    getUserFullname() {
        if (this.user) {
            return `${this.user.lastname.toUpperCase()} ${this.user.firstname}`;
        }
        return '';
    }

    getUserStatus() {
        if (this.user) {
            if (this.user.connectionStatus === 'offline') {
                return 'Hors ligne';
            } else if (this.user.connectionStatus === 'online') {
                return 'En ligne';
            } else if (this.user.connectionStatus === 'idle') {
                return 'Absent';
            } else if (this.user.connectionStatus === 'donotdisturb') {
                return 'Ne pas déranger';
            }
        }
        return '';
    }

    goToChat() {
        window.open(
            `${this.chatService.getChatFrontEndPoint()}/#/chat/${this.discussionId}`,
            '_blank'
        );
        this.close();
    }

    updateAnswer(value: string) {
        if (value.replace(/<br>/g, '') === '') {
            this.message = '';
        } else {
            this.message = value;
        }
    }

    sendMessage(event: any) {
        event.preventDefault();
        const value = document.getElementsByClassName('message-input')[0].innerHTML;
        this.updateAnswer(value);
        if (!this.message) {
            return;
        }
        this.message = this.message.slice(0, 1023);
        document.getElementsByClassName('message-input')[0].innerHTML = '';
        this.isSending = true;
        this.chatService.sendMessage(this.discussionId, this.message).subscribe((data) => {
            if (this.filesArray.length > 0) {
                for (const file of this.filesArray) {
                    file.extension = file.name.split('.')[file.name.split('.').length - 1];
                    this.chatService.createAttachment(data.id, file).subscribe(() => {
                        if (file === this.filesArray[this.filesArray.length - 1]) {
                            this.filesArray = [];
                            this.message = '';
                            this.initMessages();
                            setTimeout(() => {
                                this.scroll = true;
                                this.updateHeight = true;

                                this.isSending = false;
                                document.getElementById('message-input').focus();
                            }, 200);
                        }
                    });
                }
            } else {
                this.message = '';
                this.initMessages();

                setTimeout(() => {
                    this.scroll = true;
                    this.updateHeight = true;
                    this.isSending = false;
                    document.getElementById('message-input').focus();
                }, 1000);
            }
        });
    }

    parseHTML(event: any) {
        event.preventDefault();
        const tmpDiv = document.createElement('div');
        tmpDiv.innerHTML = event.clipboardData.getData('text/plain');
        document.getElementsByClassName('message-input')[0].innerHTML =
            tmpDiv.textContent || tmpDiv.innerText || '';
    }

    scrollToBottom() {
        const container = document.getElementsByClassName('main-container')[0];
        container.scrollTop = container.scrollHeight - container.clientHeight;
    }

    updateContainerHeight() {
        const container: any = document.getElementsByClassName('main-container')[0];
        const response: any = document.getElementsByClassName('chat-footer')[0];
        container.style.height = `calc(100% - ${response.offsetHeight}px + 82px)`;
        this.scroll = true;
    }

    getPreviousMessages() {
        this.chatService
            .getMessages(30, this.discussionId, this.messagesList[0].id)
            .subscribe((list: any[]) => {
                this.messagesList = list.reverse().concat(this.messagesList);
                document.getElementsByClassName('message-container')[0].scrollTop = 1;
            });
    }

    removeAttachment(index: number) {
        if (this.filesArray.length) {
            this.filesArray.splice(index, 1);
        }
    }

    getFormattedSize(size: number): string {
        if (size > 1000000) {
            return Math.floor(size / 1000000) + 'Mo';
        } else if (size > 1000) {
            return Math.floor(size / 1000) + 'Ko';
        } else {
            return Math.floor(size) + 'o';
        }
    }

    uploadFile() {
        this.fileElement.nativeElement.click();
    }

    onFilesAdded(file: File) {
        if (file[0]) {
            // if (file[0].size <= this.configService.getConfig().max_chat_file_size) {
            if (file[0].size <= 200 * 1024) {
                this.filesArray.push(file[0]);
                setTimeout(() => {
                    this.updateHeight = true;
                }, 200);
            } else {
                this.openErrorDialog.emit('Pièce jointe trop volumineuse');
            }
        }
    }

    deleteFile(id: number) {
        this.filesArray.splice(id, 1);
        this.fileElement.nativeElement.value = '';
        this.updateHeight = true;
    }

    close() {
        this.dialogRef.close();
    }
}
