import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EasiHttpParams } from './easi-http-params';
import { filter, tap } from 'rxjs/operators';
import { DialogService } from '../services/dialog.service';
import { ConfigService } from '../services/config.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private dialogService: DialogService, private configService: ConfigService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!(request.params instanceof EasiHttpParams) || !request.params.noError) {
            return next.handle(request).pipe(
                /**
                 * Filtrage des HttpEvent de type 0, qui correspondent à une requête correctement envoyée.
                 */
                filter((event: HttpEvent<any>) => event.type !== 0),
                tap(
                    (response: HttpResponse<any>) => {
                        // Ne fait rien de spécial
                    },
                    (error: HttpErrorResponse) => {
                        if (error.error.errorCode !== 'USER_NOT_AUTH') {
                            if (error && error.error.userMessage) {
                                this.dialogService.openErrorDialog(error.error.userMessage);
                            } else {
                                this.dialogService.openErrorDialog(
                                    'Une erreur est survenue. Si cette erreur persiste, veuillez contacter votre administrateur.'
                                );
                            }
                        }
                        this.isAuthenticated(error);
                    }
                )
            );
        } else {
            return next.handle(request);
        }
    }
    /**
     * redirige l'utilisateur
     * @param error l'erreur HTTP reçue
     */
    isAuthenticated(error: HttpErrorResponse) {
        if (error.error.errorCode && error.error.errorCode === 'USER_NOT_AUTH') {
            window.top.location.href = this.configService.getConnectFrontEndPoint() + '/#!/login';
        }
    }
}
