<div class="header" [ngStyle]="{ 'background-color': getApplicationColor() }">
    <div class="icon-userinconnu-gros"></div>
    <div
        class="icon-UserYPareo"
        *ngIf="isYpareo()"
        [tooltip]="'Utilisateur Ypareo'"
        [tooltipColor]="getApplicationColor()"
    ></div>
    <div class="ypareo-id" *ngIf="isYpareo()">{{ getUserYpareoID() }}</div>
    <div class="label">Fiche utilisateur</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content" *ngIf="user && !editionMode && !isLoading()">
    <div class="info-container-1">
        <div class="icon" [ngClass]="getRoleIcon()"></div>
        <div class="fullname">{{ getUserFullname() }}</div>
        <div class="structure">
            <div class="icon" [ngClass]="getStructureIcon()"></div>
            <div class="structure-name">{{ getUserStructure() }}</div>
        </div>
    </div>
    <div class="info-container-2">
        <div class="username">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Identifiant</div>
            <div class="label">{{ getUsername() }}</div>
        </div>
        <div class="email">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Adresse email</div>
            <div class="label" [ngClass]="{ 'no-email': !user.email, orange: !user.emailverified }">
                {{ getUserEmail() }}
            </div>
            <div class="activation-link" *ngIf="user.id && user.email && !isEmailVerified()">
                <div class="label" (click)="resendValidationMail()">
                    <div class="icon-message"></div>
                    <div class="text">Renvoyer le lien d'activation</div>
                </div>
            </div>
            <div class="activation-link" *ngIf="user.email && isEmailVerified()">
                <div class="label">
                    <div class="icon-Valide"></div>
                    <div class="text">Adresse email validée</div>
                </div>
            </div>
        </div>

        <div class="temp-password" *ngIf="showTempPassword()">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                Mot de passe temporaire
            </div>
            <div class="label">{{ getUserTempPassword() }}</div>
        </div>
        <div class="no-temp-password" *ngIf="!getUserTempPassword() && user.id">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                L'utilisateur a créé un mot de passe personnel
            </div>
            <div class="label" *ngIf="showResetTempPassword()" (click)="resetTempPassword()">
                Rétablir le mot de passe temporaire initial
            </div>
        </div>

        <div class="CGU" *ngIf="getUserCGU()">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                L'utilisateur a validé les CGU
            </div>
        </div>
        <div class="no-CGU" *ngIf="!getUserCGU()">
            <div class="legend">L'utilisateur n'a pas validé les CGU</div>
        </div>
        <div class="reminder-count" *ngIf="hasReminder()">
            <div class="legend">
                {{ user.cgu_validation_reminder_mail }} rappel(s) envoyé(s) à l'utilisateur
            </div>
        </div>
    </div>
    <div class="info-container-3" *ngIf="showDateInfo()">
        <div *ngIf="user.startaccount && !user.endaccount">
            Compte actif à partir du <i>{{ creationDateStart.toLocaleDateString() }}</i>
        </div>
        <div *ngIf="!user.startaccount && user.endaccount">
            Compte actif jusqu'au <i>{{ creationDateEnd.toLocaleDateString() }}</i>
        </div>
        <div *ngIf="user.startaccount && user.endaccount">
            Compte actif du <i>{{ creationDateStart.toLocaleDateString() }}</i> au
            <i>{{ creationDateEnd.toLocaleDateString() }}</i>
        </div>
    </div>
    <div class="info-container-4">
        <div class="roles">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Rôles</div>
            <div class="label">
                <ng-container *ngFor="let role of userRoles">
                    <div class="role" *ngIf="role.selected">
                        <div class="icon" [ngClass]="role.icon"></div>
                        <div class="name">{{ role.name }}</div>
                        <div class="formation" *ngIf="role.key === 'learner'">
                            {{ getFIStatus() }}
                        </div>
                        <div class="tags" *ngIf="getUserTags()">{{ getUserTags() }}</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="roles" *ngIf="showAdditionalRoles()">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                Rôles complémentaires
            </div>
            <div class="label">
                <ng-container *ngFor="let additionalRole of additionalRoles">
                    <div class="role" *ngIf="additionalRole.selected">
                        <div class="icon" [ngClass]="additionalRole.icon"></div>
                        <div class="name">{{ additionalRole.name }}</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="tutor" *ngIf="showTutorRoles()">
            <div>
                <div
                    class="icon-groupe"
                    (click)="openTutorUsersDialog(true)"
                    [tooltip]="'Voir les utilisateurs liés à ce tuteur'"
                    [tooltipColor]="getApplicationColor()"
                ></div>
                <div class="label" *ngIf="tutorUsers">
                    Lié à <b>{{ tutorUsers.length }}</b> apprenants et
                    <b>{{ tutorGroups.length }}</b> groupes d'apprenant
                </div>
            </div>
        </div>
    </div>
    <div class="info-container-5">
        <div class="sites">
            <div class="icon-site" *ngIf="!isSelectedRole('tutor')"></div>
            <ng-container *ngFor="let site of user.sites">
                <div
                    class="site"
                    *ngIf="!site.isCorporation"
                    [ngStyle]="{ 'background-color': getApplicationColor() }"
                >
                    {{ site.name }}
                </div>
            </ng-container>
            <div class="text" *ngIf="!getUserSitesCount()">Aucun site</div>
        </div>
        <div class="corporations" *ngIf="!isSelectedRole('tutor')">
            <div class="icon-corporation"></div>
            <ng-container *ngFor="let corporation of user.sites">
                <div
                    class="corporation"
                    *ngIf="corporation.isCorporation"
                    [ngStyle]="{ 'background-color': getApplicationColor() }"
                >
                    {{ corporation.name }}
                </div>
            </ng-container>
            <div class="text" *ngIf="!getUserCorporationsCount()">Aucune entreprise</div>
        </div>
        <div class="groups">
            <div class="icon-groupe"></div>
            <ng-container *ngFor="let group of getUserGroups()">
                <div class="group" [ngStyle]="{ 'background-color': getApplicationColor() }">
                    {{ group.name }}
                </div>
            </ng-container>
            <div class="text" *ngIf="!getUserGroupsCount()">Aucun groupe</div>
        </div>
    </div>
    <div class="info-container-6" *ngIf="canEditUser()">
        <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Applications</div>
        <div class="label">
            <div
                class="application"
                *ngFor="let application of applications"
                [ngClass]="[
                    getApplicationIcon(application.app_id),
                    application.selected ? 'selected' : 'not-selected'
                ]"
                [ngStyle]="{ 'background-color': getApplicationColor() }"
                [tooltip]="application.name"
                [tooltipColor]="getApplicationColor()"
            ></div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content class="content-editing" *ngIf="user && editionMode && !isLoading()">
    <div class="info-container-1">
        <div class="icon" [ngClass]="getRoleIcon()"></div>
        <div class="fullname">
            <input
                type="text"
                [(ngModel)]="user.firstname"
                placeholder="Prénom"
                class="placeholder-orange"
            />
            <input
                type="text"
                [(ngModel)]="user.lastname"
                placeholder="Nom"
                class="placeholder-orange"
            />
        </div>
        <div class="structure">
            <div class="icon" [ngClass]="getStructureIcon()"></div>
            <div class="structure-name" *ngIf="userId !== 0 || !isNationalAdmin()">
                {{ getUserStructure() }}
            </div>
            <lib-uimm-dropdown
                *ngIf="userId === 0 && isNationalAdmin()"
                [label]="'Structures'"
                labelDefaultColor="#ff7200"
                [labelWidth]="'200px'"
                [customClass]="'dropdown-content__right-0'"
                [maxWidth]="330"
                [(items)]="structures"
                (itemsChange)="updateStructure()"
                [design]="'boxes'"
                [headers]="{ search: true, count: true }"
            ></lib-uimm-dropdown>
        </div>
    </div>
    <div class="info-container-2">
        <div class="ypareo" *ngIf="isCurrentUserNationalAdmin()">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Ypareo ID</div>
            <input [(ngModel)]="user.externalID" placeholder="Ypareo ID" />
        </div>
        <div class="username">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Identifiant*</div>
            <div class="value-container">
                <div class="structureid">{{ user.structureid }}-</div>
                <div class="label" *ngIf="userId > 0 && !isCurrentUserNationalAdmin()">
                    {{ getUsername() }}
                </div>
                <input
                    *ngIf="userId <= 0 || isCurrentUserNationalAdmin()"
                    [(ngModel)]="user.username"
                    placeholder="Identifiant"
                    class="placeholder-orange"
                />
            </div>
        </div>
        <div class="email">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Adresse email</div>
            <input [(ngModel)]="user.email" placeholder="Adresse email" />

            <div class="activation-link" *ngIf="user.email && isEmailVerified()">
                <div class="label">
                    <div class="icon-Valide"></div>
                    <div class="text">Adresse email validée</div>
                </div>
            </div>
        </div>

        <div class="temp-password" *ngIf="showTempPassword()">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                Mot de passe temporaire
            </div>
            <div class="label">{{ getUserTempPassword() }}</div>
        </div>
        <div class="no-temp-password" *ngIf="!getUserTempPassword() && user.id">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                L'utilisateur a créé un mot de passe personnel
            </div>
            <div class="label" *ngIf="showResetTempPassword()" (click)="resetTempPassword()">
                Rétablir le mot de passe temporaire initial
            </div>
        </div>
    </div>
    <div class="info-container-3">
        <div class="date">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                Date de début du compte
            </div>
            <div class="datepicker">
                <input
                    [matDatepicker]="creationDateStartInput"
                    [(ngModel)]="creationDateStart"
                    (focus)="creationDateStartInput.open()"
                    placeholder="Date de début"
                />
                <mat-datepicker #creationDateStartInput></mat-datepicker>
            </div>
        </div>
        <div class="date">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                Date de fin du compte
            </div>
            <div class="datepicker">
                <input
                    [matDatepicker]="creationDateEndInput"
                    [(ngModel)]="creationDateEnd"
                    (focus)="creationDateEndInput.open()"
                    placeholder="Date de fin"
                />
                <mat-datepicker #creationDateEndInput></mat-datepicker>
            </div>
        </div>
    </div>
    <div class="info-container-4">
        <div class="roles">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Rôles*</div>
            <div class="label">
                <ng-container *ngFor="let role of roles">
                    <div
                        class="icon"
                        [ngClass]="[role.icon, !isSelectedRole(role.key) ? 'not-selected' : '']"
                        *ngIf="showRole(role.key)"
                        (click)="selectRole(role.key)"
                        [ngStyle]="{ 'background-color': getApplicationColor() }"
                        [tooltip]="role.name"
                        [tooltipColor]="getApplicationColor()"
                    ></div>
                </ng-container>
            </div>
        </div>
        <div class="additional-roles">
            <div class="legend" [ngStyle]="{ color: getApplicationColor() }">
                {{ getAdditionnalRoleLabel() }}
            </div>
            <div class="label">
                <div class="learner" *ngIf="showLearnerRoles()" [ngStyle]="getFIFCSelectorStyles()">
                    <div class="formation">
                        <div
                            class="icon"
                            (click)="toggleFI()"
                            [ngClass]="{
                                'icon-selected': user.FI === true,
                                'icon-select': user.FI !== true
                            }"
                        ></div>
                        <div class="text">Formation initiale</div>
                    </div>
                    <div class="formation">
                        <div
                            class="icon"
                            (click)="toggleFC()"
                            [ngClass]="{
                                'icon-selected': user.FI === false,
                                'icon-select': user.FI !== false
                            }"
                        ></div>
                        <div class="text">Formation continue</div>
                    </div>
                </div>
                <div class="teacher" *ngIf="showTeacherRoles() && showAdditionalRoles()">
                    <ng-container *ngIf="isSelectedRole('internalTeacher')">
                        <div
                            class="icon"
                            *ngFor="let tag of tags"
                            (click)="selectTag(tag)"
                            [ngClass]="[tag.icon, !isSelectedTag(tag) ? 'not-selected' : '']"
                            [ngStyle]="{ 'background-color': getApplicationColor() }"
                            [tooltip]="tag.name"
                            [tooltipColor]="getApplicationColor()"
                        ></div>
                    </ng-container>
                    <ng-container *ngFor="let additionalRole of additionalRoles">
                        <div
                            class="icon"
                            [ngClass]="[
                                additionalRole.icon,
                                additionalRole.selected ? 'selected' : 'not-selected',
                                isAdditionalRoleLocked(additionalRole) ? 'locked' : ''
                            ]"
                            (click)="toggleAdditionalRole(additionalRole)"
                            [ngStyle]="{ 'background-color': getApplicationColor() }"
                            [tooltip]="getAdditionalRoleTooltip(additionalRole)"
                            [tooltipColor]="getApplicationColor()"
                        ></div>
                    </ng-container>
                </div>
                <div class="tutor" *ngIf="showTutorRoles() && getStructureSelected()">
                    <div
                        class="icon-tuteurentreprise"
                        (click)="openTutorUsersDialog(false)"
                        [tooltip]="'Modifier les utilisateurs liés à ce tuteur'"
                        [tooltipColor]="getApplicationColor()"
                    ></div>
                    <div class="label" *ngIf="tutorUsers" (click)="openTutorUsersDialog(false)">
                        <b>{{ tutorUsers.length }}</b> apprenants et
                        <b>{{ tutorGroups.length }}</b> groupes d'apprenants
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container-5" *ngIf="getStructureSelected()">
        <div class="sites" *ngIf="!isSelectedRole('localAdmin') && !isSelectedRole('tutor')">
            <div class="icon-site"></div>
            <lib-uimm-dropdown
                [label]="getSitesLabel()"
                [labelWidth]="'150px'"
                [maxHeight]="230"
                [labelDefaultColor]="getSitesLabelDefaultColor()"
                [(items)]="sites"
                [multiselect]="true"
                [headers]="{ search: true, count: true }"
            ></lib-uimm-dropdown>
        </div>
        <div class="corporations" *ngIf="!isSelectedRole('localAdmin') && !isSelectedRole('tutor')">
            <div class="icon-corporation"></div>
            <lib-uimm-dropdown
                [label]="'Entreprises'"
                [labelWidth]="'150px'"
                [maxHeight]="200"
                [(items)]="corporations"
                [multiselect]="true"
                [headers]="{ search: true, count: true }"
            ></lib-uimm-dropdown>
        </div>
        <div class="groups">
            <div class="icon-groupe"></div>
            <lib-uimm-dropdown
                *ngIf="isSelectedRole('learner') || isSelectedRole('prospect')"
                [label]="'Groupes'"
                [labelWidth]="'150px'"
                [maxHeight]="230"
                [(items)]="learnerGroups"
                [multiselect]="true"
                [headers]="{ search: true, count: true }"
            ></lib-uimm-dropdown>
            <lib-uimm-dropdown
                *ngIf="!isSelectedRole('learner') && !isSelectedRole('prospect')"
                [label]="'Groupes'"
                [labelWidth]="'150px'"
                [maxHeight]="230"
                [(items)]="teacherGroups"
                [multiselect]="true"
                [headers]="{ search: true, count: true }"
            ></lib-uimm-dropdown>
        </div>
    </div>
    <div class="info-container-6" *ngIf="getStructureSelected()">
        <div class="legend" [ngStyle]="{ color: getApplicationColor() }">Applications</div>
        <div class="label">
            <div
                class="application"
                *ngFor="let application of applications"
                [ngClass]="[
                    getApplicationIcon(application.app_id),
                    application.selected ? 'selected' : 'not-selected',
                    isApplicationLocked(application) ? 'locked' : ''
                ]"
                [ngStyle]="{ 'background-color': getApplicationColor() }"
                (click)="toggleApplication(application)"
                [tooltip]="getApplicationTooltip(application)"
                [tooltipColor]="getApplicationColor()"
            ></div>
            <div
                class="icon-Nouvelleversioncontenu"
                *ngIf="canResetApplicationAccess()"
                (click)="resetApplicationsAccess()"
                [tooltip]="'Restaurer les droits par défaut du rôle'"
                [tooltipColor]="getApplicationColor()"
            ></div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content class="loading" *ngIf="isLoading()">
    <app-loading></app-loading>
</mat-dialog-content>
<div class="footer" [ngStyle]="{ 'background-color': getApplicationColor() }" *ngIf="canEditUser()">
    <div
        class="button"
        *ngIf="!editionMode"
        [ngStyle]="{ color: getApplicationColor() }"
        (click)="toggleEditionMode()"
    >
        Modifier la fiche
    </div>
    <div
        class="button"
        *ngIf="editionMode && userId > 0 && !isLoading('updateUser')"
        [ngClass]="{ disabled: !canCreateOrEditUser() }"
        [ngStyle]="{ color: getApplicationColor() }"
        (click)="editUser()"
    >
        Enregister les modifications
    </div>
    <div
        class="button"
        *ngIf="editionMode && userId <= 0 && !isLoading('createUser')"
        [ngClass]="{ disabled: !canCreateOrEditUser() }"
        [ngStyle]="{ color: getApplicationColor() }"
        (click)="createUser()"
    >
        Créer l'utilisateur
    </div>
    <div class="button" *ngIf="isLoading('createUser') || isLoading('updateUser')">
        <app-loading></app-loading>
    </div>
</div>
<div
    class="footer-deletion"
    [ngStyle]="{ 'background-color': getApplicationColor() }"
    *ngIf="isCurrentUserNationalAdmin() && !isLoading() && editionMode && user.id"
>
    <div class="button" (click)="deleteUser()">Supprimer définitivement le compte</div>
</div>
