<div class="news-container" [ngClass]="{ opened: isOpened() }">
    <div class="title-container">
        <div class="title">News</div>
        <div class="icon-close" (click)="close()"></div>
    </div>
    <div class="filter-container">
        <div class="dates-container">
            <span class="date-long-text">Date de publication:</span> du
            <input
                class="date-input"
                [(ngModel)]="startDate"
                [matDatepicker]="startDateInput"
                (click)="startDateInput.open()"
                (dateChange)="getNews()"
            />
            <mat-datepicker #startDateInput></mat-datepicker>
            au
            <input
                class="date-input"
                [(ngModel)]="endDate"
                [matDatepicker]="endDateInput"
                (click)="endDateInput.open()"
                (dateChange)="getNews()"
            />
            <mat-datepicker #endDateInput></mat-datepicker>
        </div>
        <div class="search-container">
            <input
                class="library-search-field"
                [(ngModel)]="search"
                placeholder="Chercher..."
                (keyup.enter)="getNews()"
            />
            <span class="icon-search" (click)="getNews()"></span>
            <span class="icon-Fermerdetails" *ngIf="search" (click)="cancelSearch()"></span>
        </div>
        <div class="cancel-filters" (click)="cancelFilters()">
            <span>Annuler les filtres</span>
        </div>
    </div>
    <div class="news-count">
        <span class="news-title">
            <b>{{ getUnreadNewsCount() }}</b> news non lue(s)
        </span>
        <span
            class="icon-down"
            (click)="toggleUnreadNews()"
            [ngClass]="{ opened: isOpenedUnreadNews() }"
        ></span>
        <div class="mark-all-as-read" *ngIf="isOpenedUnreadNews()" (click)="markAllAsRead()">
            Tout marquer comme lu
        </div>
    </div>
    <div class="news-element" [ngClass]="{ opened: isOpenedUnreadNews() }">
        <app-news-entry
            [read]="false"
            [news]="news"
            *ngFor="let news of unreadNews"
        ></app-news-entry>
    </div>
    <div class="news-count">
        <span class="news-title">
            <b>{{ getReadNewsCount() }}</b> news lue(s)
        </span>
        <span
            class="icon-down"
            (click)="toggleReadNews()"
            [ngClass]="{ opened: isOpenedReadNews() }"
        ></span>
    </div>
    <div class="news-element" [ngClass]="{ opened: isOpenedReadNews() }">
        <app-news-entry [read]="true" [news]="news" *ngFor="let news of readNews"></app-news-entry>
    </div>
</div>
