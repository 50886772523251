import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';

import { ApplicationService } from './services/application.service';
import { ConfigService } from './services/config.service';
import { DialogService } from './services/dialog.service';
import { LoadingService } from './services/loading.service';
import { LoginService } from './services/login.service';
import { EventService } from './services/event.service';

/**
 *  composant parent de l'application.
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    /**
     * @param {string} title contient le titre de l'application.
     * @param {SafeResourceUrl} iframeUrl url d'une iframe q configurer.
     */
    title = 'easi-shared';

    iframeUrl: SafeResourceUrl;
    appReady: boolean;

    constructor(
        private sanitizer: DomSanitizer,
        private applicationService: ApplicationService,
        private configService: ConfigService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private loadingService: LoadingService,
        private eventService: EventService
    ) {}

    ngOnInit() {
        this.appReady = false;
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.configService.getNotificationEndPoint() + '/notifications_iframe.html'
        );
        this.loginService.getStructures().subscribe(() => {
            this.loginService.getCurrentSession().subscribe(() => {
                if (this.loginService.getUser()) {
                    this.applicationService.getApplications().subscribe(() => {
                        this.appReady = true;
                    });
                } else {
                    this.appReady = true;
                }
            });
        });
        if (document.location.href.split('#')[1] === 'mobile') {
            this.dialogService.openMobileAppDialog();
        }
    }

    isLoading(): boolean {
        return this.loadingService.isSomethingLoading();
    }

    displayHeader() {
        return !new URLSearchParams(window.location.search).get('chatOnly');
    }
}
