import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Group } from '../structures/group';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    constructor(private http: HttpClient) {}

    @log() getGroups(params: any): Observable<any> {
        return this.http.get(`/groups`, { params });
    }

    @log() getGroup({ ...params }): Observable<Group> {
        const { groupid } = params;

        return this.http.get(`/groups/${groupid}`, { params });
    }

    @log() getTutorGroups(groupId: number): Observable<any> {
        return this.http.get(`/groups/${groupId}/tutor`);
    }

    @log() addGroupToTutor(groupId: number, tutorId: number): Observable<any> {
        return this.http.post(`/groups/${groupId}/tutor/${tutorId}`, undefined);
    }

    @log() deleteGroupFromTutor(groupId: number, tutorId: number): Observable<any> {
        return this.http.delete(`/groups/${groupId}/tutor/${tutorId}`);
    }

    @log() getGroupHistory(groupId: number, startDate: Date, endDate: Date) {
        startDate.setMilliseconds(0);
        endDate.setMilliseconds(0);
        const params: any = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        };

        return this.http.get(`/groups/${groupId}/history`, { params });
    }
}
