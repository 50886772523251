import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, forkJoin } from 'rxjs';

import { IframeService } from '../../services/iframe.service';
import { LoginService } from '../../services/login.service';
import { HelpService } from '../../services/help.service';
import { DialogService } from '../../services/dialog.service';
import { ApplicationService } from 'client-src/app/services/application.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    constructor(
        private sanitizer: DomSanitizer,
        private applicationService: ApplicationService,
        private iframeService: IframeService,
        private loginService: LoginService,
        private helpService: HelpService,
        private dialogService: DialogService
    ) {}

    subscriptions = new Subscription();
    helpData: any;
    searchTerm = '';
    filteredHelpData: any;

    showAssistance: boolean;
    showNationalAssistance: boolean;

    contacts: Array<any>;

    easiItems: Array<any> = [];

    ngOnInit() {
        this.showAssistance = false;
        this.showNationalAssistance = false;
        this.applicationService.activeApplicationSet.subscribe(() => {
            this.helpService
                .getTrainingHelp(this.applicationService.getCurrentApplication().app_id)
                .subscribe((data: any) => {
                    this.helpData = data;
                    this.filteredHelpData = data;

                    this.helpData.sections.map((section) => {
                        if (section.easi_items) {
                            section.easi_items.map((easi_item) => {
                                this.easiItems.push(easi_item.content);
                            });
                        }
                        if (section.subsections) {
                            section.subsections.map((subsection) => {
                                if (subsection.easi_items) {
                                    subsection.easi_items.map((easi_item) => {
                                        this.easiItems.push(easi_item.content);
                                    });
                                }
                            });
                        }
                    });

                    forkJoin(
                        this.easiItems.map((contentId) => this.helpService.getContent(contentId))
                    ).subscribe({
                        next: (res) => {
                            this.easiItems = res;
                        }
                    });
                });

            const filter = {
                application: this.applicationService.getCurrentApplication().app_id,
                structureid: this.loginService.getUser().structureid + '|0'
            };

            this.helpService.getContacts(filter).subscribe((contacts) => {
                this.contacts = contacts.filter((contact) => !contact.hidden);
                if (this.contacts) {
                    this.contacts.map((contact) => {
                        if (!contact.structureid) {
                            this.showNationalAssistance = true;
                        } else {
                            this.showAssistance = true;
                        }
                        contact.assistance_card = this.sanitizer.bypassSecurityTrustHtml(
                            contact.assistance_card
                        );
                        contact.contact_card = this.sanitizer.bypassSecurityTrustHtml(
                            contact.contact_card
                        );
                    });
                }
            });
        });
    }

    getContentFromID(_content: any) {
        return this.easiItems.filter((content) => content.id === _content.content)[0];
    }

    /**
     * après soumission du this.searchTerm de l'input de recherche, met à jour this.filteredHelpData en filtrant le contenu de l'aide par le terme de recherche
     */
    filterData(): void {
        if (!this.searchTerm || this.searchTerm === '') {
            this.filteredHelpData = this.helpData;
            return;
        }
        const search = this.searchTerm.toLowerCase();

        const mappedSections = this.helpData.sections.map((section) => {
            if (section.subsections) {
                const filteredSubsections = section.subsections.map((subsection) => {
                    if (subsection.video_items) {
                        return this.filterVideoItems(subsection, search);
                    } else if (subsection.question_items) {
                        return this.filterQuestionItems(subsection, search);
                    }
                });
                if (!filteredSubsections.find((i) => i !== undefined)) {
                    return undefined;
                }
                return {
                    ...section,
                    subsections: filteredSubsections
                };
            } else if (section.video_items) {
                return this.filterVideoItems(section, search);
            } else if (section.question_items) {
                return this.filterQuestionItems(section, search);
            }
        });
        this.filteredHelpData = {
            ...this.helpData,
            sections: [...mappedSections]
        };
    }

    /**
     * filtre un tableau d'éléments video en fonction de la recherche
     * @param sectionOrSubsection l'élément à filtrer
     * @param search la recherche effectuée (string)
     */
    filterVideoItems(sectionOrSubsection: any, search: string) {
        const videoSection = sectionOrSubsection.video_items.filter((video) => {
            return this.check(video.name, search) || this.check(video.description, search);
        });
        if (videoSection.length) {
            return {
                ...sectionOrSubsection,
                video_items: [...videoSection]
            };
        } else {
            return undefined;
        }
    }

    /**
     * filtre un tableau d'éléments question en fonction de la recherche
     * @param sectionOrSubsection l'élément à filtrer
     * @param search la recherche effectuée (string)
     */
    filterQuestionItems(sectionOrSubsection: any, search: string) {
        const questionSection = sectionOrSubsection.question_items.filter((quest) => {
            return (
                this.check(quest.question_title, search) || this.check(quest.question_test, search)
            );
        });
        if (questionSection.length) {
            return {
                ...sectionOrSubsection,
                question_items: [...questionSection]
            };
        } else {
            return undefined;
        }
    }

    /**
     * vérifie si la string `search` est contenue dans `item`
     */
    check(item: string, search: string): boolean {
        return item.toLowerCase().trim().includes(search);
    }

    /**
     * vérifie si l'élément en paramètre doit être caché pour les roles de l'utilisateur (this.roles)
     * @param data un élément de l'arbre de données d'aide this.helpData
     */
    canShowElementOfData(data): boolean {
        if (
            data &&
            data.hidden_for &&
            data.hidden_for.some(
                (role) =>
                    this.loginService.getUser().roles[role] && role.toString() === role.toString()
            )
        ) {
            return false;
        }
        return true;
    }

    resetSearch() {
        this.searchTerm = '';
        this.filteredHelpData = this.helpData;
    }

    /**
     * ouvre le modale de la vidéo cliquée
     * @param video l'objet video
     */
    openVideoDialog(video: any): void {
        this.dialogService.openVideoDialog(video);
    }

    openContentDialog(content: any): void {
        this.dialogService.openContentDialog(content);
    }

    getContentImg(content: any) {
        return content.img
            ? content.img
            : 'https://cdn.easi-training.fr/help/easitraining/img/default.jpg';
    }

    /**
     * demande au iframeService si le composant est ouvert
     */
    isOpened(): boolean {
        return this.iframeService.isOpenedComponent('HelpComponent');
    }

    /**
     * ajoute les classes css dynamiquement au container
     */
    getClasses(): string {
        if (this.applicationService.getCurrentApplication()) {
            let classList = this.iframeService.isOpenedComponent('HelpComponent') ? 'opened' : '';
            classList += ' ' + this.applicationService.getCurrentApplication().app_id;
            return classList;
        }
    }

    getStyles() {
        if (this.applicationService.getCurrentApplication()) {
            return {
                'background-color': this.applicationService.getCurrentApplication().color
            };
        }
    }

    /**
     * ferme le composant
     */
    close(): void {
        this.iframeService.closeComponent('HelpComponent');
    }
}
