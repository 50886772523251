import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ApplicationService } from './application.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from './login.service';
import { LogService } from './log.service';
import { NotificationService } from './notification.service';
import { IframeService } from './iframe.service';
import { DialogService } from './dialog.service';
import { UserService } from './user.service';
import { User } from '../structures/user';
import { GroupService } from './group.service';
import { SiteService } from './site.service';
import { Group } from '../structures/group';
import { Site } from '../structures/site';

/**
 *  service gerant les evenements dans l'application.
 */
@Injectable({
    providedIn: 'root'
})
export class EventService {
    constructor(
        private applicationService: ApplicationService,
        private logService: LogService,
        private loginService: LoginService,
        private notificationService: NotificationService,
        private cookieService: CookieService,
        private iframeService: IframeService,
        private dialogService: DialogService,
        private usersService: UserService,
        private groupsService: GroupService,
        private sitesService: SiteService
    ) {
        window.addEventListener(
            'message',
            (event: MessageEvent) => {
                this.receiveMessage(event);
            },
            false
        );
        window.addEventListener(
            'click',
            (event: Event) => {
                this.receiveClick(event);
            },
            false
        );
    }

    /**
     * @param {Subject<void>} closeIframe pour avertir de la fermeture de l'iFrame.
     * @param {Subject<void>} refreshUser pour avertir qu'il faut rafraichir un utilisateur.
     * @param {Subject<void>} reduceNewsCount pour mettre à jour le nombre de news non lues.
     */

    closeIframe: Subject<void> = new Subject();
    reduceNewsCount: Subject<void> = new Subject();

    /**
     *  configurer l'application courante de l'application.
     * @param {string} url l'url a envoyer.
     * @returns {void}
     */
    setCurrentApplication(url: string): void {
        this.getIcons();
        this.applicationService.setActiveApplications(url);
    }

    /**
     *  configurer les cookies concernant les structures.
     * @param data les donnes recues sur la structure.
     * @returns {void}
     */
    setCookies(data): void {
        this.cookieService.set('structureId', data.structureid, { secure: true, sameSite: 'None' });
        this.cookieService.set('structureName', data.structurename, {
            secure: true,
            sameSite: 'None'
        });
    }

    /**
     *  envoyer a la plateforme l'id et le nom de la structure.
     * @returns {void}
     */
    getCookies(): void {
        const structureid = this.cookieService.get('structureId');
        const structurename = this.cookieService.get('structureName');

        if (this.loginService.getUser().roles.nationalAdmin && structureid) {
            window.parent.postMessage(
                { structureid: parseInt(structureid, 10), structurename: structurename },
                '*'
            );
        }
    }

    /**
     *  recuperer l'URL de l'application parente a un intervale donne.
     * @returns {void}
     */
    getParentURL(): void {
        window.parent.postMessage('getURL', '*');
    }

    postStructures(): void {
        window.parent.postMessage({ structures: this.loginService.getStructuresHistory() }, '*');
    }

    postCurrent(): void {
        window.parent.postMessage({ current: this.loginService.getUser() }, '*');
    }

    postOurApplication(data: any): void {
        window.parent.postMessage({ ourapplication: data }, '*');
    }

    updateNewsCount(event: any) {
        if (event.seen) {
            this.reduceNewsCount.next();
        }
    }

    /**
     *  fonction qui recoit l'endroit clique et ferme l'iframe ou non.
     * @param event l'evenement click recu.
     * @returns {void}
     */
    receiveClick(event: any): void {
        if (event.target.id === 'shared-root' || event.target.id === 'pageHeader') {
            this.iframeService.closeAllComponent();
        }
    }

    getIcons(): void {
        window.parent.postMessage('getIcons', '*');
    }

    /**
     *  recoit un message et l'emet a la plateforme.
     * @returns {void}
     */
    receiveMessage(event: MessageEvent): void {
        if (event.data === 'getStructures') {
            this.postStructures();
        }
        if (event.data === 'userDisconnected') {
            this.dialogService.openLoginTimeoutDialog();
        }
        if (event.data.getOurApplication) {
            this.applicationService
                .getOurApplications(event.data.getOurApplication)
                .subscribe((data: any) => {
                    this.postOurApplication(data);
                });
        }
        if (event.data === 'getCurrent') {
            this.postCurrent();
        }
        if (event.data.url_parent) {
            this.setCurrentApplication(event.data.url_parent);
            this.postStructures();
            this.getCookies();
        }
        if (event.data.setCookies) {
            this.setCookies(event.data.setCookies);
        }
        if (event.data === 'Notifications ready') {
            this.notificationService.init();
        }
        if (event.data.icons) {
            this.loginService.setIconsForCurrentUser(event.data.icons);
        }
        if (event.data.news) {
            this.updateNewsCount(event.data);
        }
        if (event.data.viewUser !== undefined) {
            this.dialogService.openUserDetails(event.data.viewUser);
        }
        if (event.data.openUserHistory) {
            this.dialogService.openUserHistory(event.data);
        }
        if (event.data.getUserHistory) {
            this.getUserHistory(event);
        }
        if (event.data.getGroupHistory) {
            this.getGroupHistory(event);
        }
        if (event.data.getUser) {
            this.getUserForTraining(event);
        }
        if (event.data.getUsers) {
            this.getUsersForTraining(event);
        }
        if (event.data.getUsersLight) {
            this.getUsersLightForTraining(event);
        }
        if (event.data.getGroup) {
            this.getGroupForTraining(event);
        }
        if (event.data.getSite) {
            this.getSiteForTraining(event);
        }
        if (event.data.status) {
            this.dialogService.openCall(event.data);
            this.callUser(event);
        }
        if (event.data.startChat) {
            if (new URLSearchParams(window.location.search).get('chatOnly')) {
                this.dialogService.openChat(JSON.parse(event.data.startChat));
            }
        }
        if (event.data.chatToOpen) {
            this.dialogService.openChat({ discussionid: event.data.chatToOpen });
        }
        if (event.data.mousemoved) {
            this.notificationService.markMouseAsMoved();
        }

        if (event.data.loginHub3e) {
            this.loginService.logWithHub3e(event.data.user_id, event.data.token);
        }
    }

    getUserForTraining(event: MessageEvent): void {
        if (event.data.userId) {
            this.usersService.getUser(event.data.userId).subscribe((data: User[]) => {
                window.parent.postMessage({ user: data, timeStamp: event.data.timeStamp }, '*');
            });
        }
    }

    getUsersForTraining(event: MessageEvent): void {
        if (event.data.params) {
            this.usersService.getUsers(event.data.params).subscribe((data: User[]) => {
                window.parent.postMessage({ users: data, timeStamp: event.data.timeStamp }, '*');
            });
        }
    }

    getUsersLightForTraining(event: MessageEvent): void {
        if (event.data.params) {
            this.usersService.getUsersLight(event.data.params).subscribe((data: User[]) => {
                window.parent.postMessage({ users: data, timeStamp: event.data.timeStamp }, '*');
            });
        }
    }

    getGroupForTraining(event: MessageEvent): void {
        if (event.data.params) {
            this.groupsService.getGroup(event.data.params).subscribe((data: Group) => {
                window.parent.postMessage({ group: data, timeStamp: event.data.timeStamp }, '*');
            });
        }
    }
    getSiteForTraining(event: MessageEvent): void {
        if (event.data.params) {
            this.sitesService.getSite(event.data.params).subscribe((data: Site) => {
                window.parent.postMessage({ site: data, timeStamp: event.data.timeStamp }, '*');
            });
        }
    }

    getUserHistory(event: MessageEvent): void {
        if (event.data.params) {
            const { userId, startDate, endDate } = event.data.params;
            this.usersService.getUserHistory(userId, startDate, endDate).subscribe((data: any) => {
                window.parent.postMessage({ history: data, timeStamp: event.data.timeStamp }, '*');
            });
        }
    }

    getGroupHistory(event: MessageEvent): void {
        if (event.data.params) {
            const { groupId, startDate, endDate } = event.data.params;
            this.groupsService
                .getGroupHistory(groupId, startDate, endDate)
                .subscribe((data: any) => {
                    window.parent.postMessage(
                        { history: data, timeStamp: event.data.timeStamp },
                        '*'
                    );
                });
        }
    }

    callUser(event: MessageEvent) {
        this.usersService.callUser(event.data).subscribe();
    }
}
