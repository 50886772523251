<div
    class="message-container {{ position }}"
    [ngClass]="{ disabled: disabled }"
    *ngIf="!message.deleted"
>
    <div class="message-owner">
        <span>{{ isCurrentOwner() ? '' : author + ', ' }}{{ timestamp }}</span>
    </div>
    <div class="message" [class.strong]="!isRead()">
        <div id="icon-container" *ngIf="!isCurrentOwner()">
            <span class="icon icon-Messages-cercle"></span>
            <div id="initials">
                <span>{{ initials }}</span>
            </div>
        </div>
        <div class="message-content" [ngClass]="{ editing: isBeingEdited || message.isEditing }">
            <span *ngFor="let text of content; let i = index">
                <span *ngIf="isBeingEdited || message.isEditing" class="editing-label"
                    >en cours de modification... (Echap pour annuler)</span
                >
                <span [innerHTML]="text"></span>
                <a target="blank" href="{{ links[i] }}" *ngIf="links[i]">{{ links[i] }}</a>
            </span>
            <div class="attachment-container" *ngFor="let attachment of message.attachments">
                <img
                    class="pic-preview"
                    alt="preview"
                    *ngIf="isImage(attachment)"
                    [src]="getAttachmentUrl(attachment)"
                />
                <!-- <span class="icon-document"></span> -->
                <a class="name" [href]="downloadAttachment(attachment.name)">{{
                    attachment.name
                }}</a>
                <span class="size">({{ getFormattedSize(attachment.size) }})</span>
            </div>
        </div>
    </div>
</div>

<div class="message-container {{ position }} deleted" *ngIf="message.deleted">
    <div class="message-owner">
        <span>{{ isCurrentOwner() ? '' : author + ', ' }}{{ timestamp }}</span>
    </div>
    <div class="message">
        <div id="icon-container" *ngIf="!isCurrentOwner()">
            <span class="icon icon-Messages-cercle"></span>
            <div id="initials">
                <span>{{ initials }}</span>
            </div>
        </div>
        <div class="message-content deleted">
            <span>
                <span>Ce message a été supprimé.</span>
            </span>
        </div>
    </div>
</div>
