<div
    class="notif-popup-container"
    [ngStyle]="{ 'background-color': notification?.color }"
    [ngClass]="{ opened: isOpened(), hidden: !isOpened() }"
    (click)="goToNotification()"
    (clickOutside)="closePopupWithoutPreventing()"
>
    <div class="header">
        <div class="icon" [ngClass]="notification?.icon"></div>
        <div class="title">easi {{ notification?.name }}</div>
        <div class="icon-close" (click)="closePopup($event)"></div>
    </div>
    <div class="body">{{ notification?.title }}</div>
</div>
