<div
    class="chat-popup-container"
    [ngClass]="{ opened: isOpened(), hidden: !isOpened() }"
    (click)="openChatDialog($event)"
>
    <div class="header">
        <div class="icon-Messages"></div>
        <div class="title">easi chat</div>
        <div class="icon-close" (click)="closePopup($event)"></div>
    </div>
    <div class="body">{{ getMessage() }}</div>
</div>
