<div class="dialog-header">
    <span class="icon-Assignationmultiple"></span>
    <div class="title">Suivi des connexions</div>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="dialog-content" *ngIf="!isLoading()">
    <div class="user">
        <div class="icon-apprenant"></div>
        <div class="name">{{ getUserName() }}</div>
        <div class="connexions-count">{{ getConnexionsCount() }}</div>
    </div>
    <div class="creation-date">
        Inscrit le <b>{{ getCreationDate() }}</b>
    </div>
    <div class="dates-selection">
        Du
        <input
            class="datepicker"
            [(ngModel)]="startDate"
            [matDatepicker]="startDateInput"
            (click)="startDateInput.open()"
            (keydown.enter)="onDateStartChange($event)"
            (dateChange)="onDateStartChange($event)"
        />
        <mat-datepicker #startDateInput></mat-datepicker>
        au
        <input
            class="datepicker"
            [(ngModel)]="endDate"
            [matDatepicker]="endDateInput"
            (click)="endDateInput.open()"
            (keydown.enter)="onDateEndChange($event)"
            (dateChange)="onDateEndChange($event)"
        />
        <mat-datepicker #endDateInput></mat-datepicker>
    </div>
    <div class="separator"></div>
    <div class="scrollbar-container">
        <div class="connexion" *ngFor="let connexion of connexions">
            <div class="date">{{ getFormattedDate(connexion) }}</div>
            <div class="time">{{ getFormattedTime(connexion) }}</div>
        </div>
        <div class="no-connexion" *ngIf="!connexions || !connexions.length">
            Pas de connexion enregistrée pour cet utilisateur sur la période demandée
        </div>
    </div>
</div>
<div class="dialog-content" *ngIf="isLoading()">
    <app-loading></app-loading>
</div>
