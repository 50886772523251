<div
    id="pageHeader"
    [ngStyle]="{ 'background-color': getApplicationColor() }"
    [ngClass]="{ stripe: getHeaderStripe() }"
>
    <div class="application-container">
        <div class="application-name">
            <span
                class="icon icon-menu"
                (click)="toggleApplicationMenu()"
                *ngIf="showToggleIcons()"
            ></span>
            <span class="icon-logoeasi" [ngStyle]="getHeaderIconStyle()"></span>
            <span class="text">{{ getServerName() }}</span>
        </div>
    </div>

    <div
        class="notif-button"
        (click)="toggleNotificationMenu()"
        *ngIf="showToggleIcons()"
        [tooltip]="'Notifications'"
        [tooltipTextColor]="getApplicationColor()"
        tooltipColor="#fff"
    >
        <span
            class="icon icon-notifications"
            [ngClass]="{ 'ding-dong-animation': getNbNotification() }"
        ></span>
        <span class="nb-notif" [ngClass]="{ 'ding-dong-animation': getNbNotification() }">
            {{ getNbNotification() }}
        </span>
    </div>

    <div
        class="notif-button media"
        (click)="toggleNotificationMenu()"
        *ngIf="showToggleIcons()"
        [tooltip]="'Notifications'"
        [tooltipTextColor]="getApplicationColor()"
        tooltipColor="#fff"
    >
        <span class="icon icon-notifications"></span>
        <span class="nb-notif">{{ getNbNotification() }}</span>
    </div>

    <nav
        class="icon-container"
        [ngClass]="centerIcons?.length && centerIcons?.length === 1 ? 'force-display' : ''"
    >
        <div *ngFor="let link of centerIcons" (click)="goTo(link.key)" class="menu-item">
            <span
                class="icon {{ link.icon }}"
                [tooltip]="link.label"
                [tooltipTextColor]="getApplicationColor()"
                tooltipColor="#fff"
            ></span>
            <div class="numberNews" *ngIf="link.key == 'newsList' && numberNewsUnseen > 0">
                {{ numberNewsUnseen }}
            </div>
        </div>
    </nav>

    <div class="user-container">
        <div class="planning" *ngIf="displayPlanning()">
            <div>
                Maintenance Dev : <b>{{ maintenanceN1User }} / {{ maintenanceN2User }}</b>
            </div>
            <div>
                Daywatch : <b>{{ daywatchUser }}</b>
            </div>
        </div>
        <div class="current-date">{{ currentDate | date: 'dd/MM/yyyy' }}</div>
        <div class="chat-button" *ngIf="hasChatAccess()" (click)="goToChat()">
            <span
                class="icon icon-Messages"
                *ngIf="showToggleIcons() && getNbMessage() === null"
                [tooltip]="'Accéder à Chat'"
                [tooltipTextColor]="getApplicationColor()"
                tooltipColor="#fff"
            ></span>
            <span
                class="icon icon-Messages-cercle"
                *ngIf="showToggleIcons() && getNbMessage() !== null"
                [tooltip]="'Accéder à Chat'"
                [tooltipTextColor]="getApplicationColor()"
                tooltipColor="#fff"
            ></span>
            <span class="nb-message">{{ getNbMessage() }}</span>
        </div>
        <div
            class="icon-Voirplus"
            (click)="toggleIconsMenu()"
            *ngIf="centerIcons?.length && centerIcons?.length > 1"
        ></div>
        <div class="avatar-container">
            <div
                class="empty avatar"
                [ngClass]="getConnectionStatus()"
                (click)="toggleConnectionStatus()"
                *ngIf="!getAvatar()"
            >
                {{ getUserInitial() }}
            </div>
            <div
                class="avatar"
                *ngIf="getAvatar()"
                [ngClass]="getConnectionStatus()"
                (click)="toggleConnectionStatus()"
            >
                <img [src]="getAvatar()" />
            </div>
        </div>

        <div class="user-firstname" (click)="toggleUserMenu($event)">
            {{ getUserFirstname() }}
        </div>
        <div class="user-menu">
            <span class="icon icon-down" (click)="toggleUserMenu($event)"></span>
        </div>
        <nav class="icon-container">
            <div *ngFor="let link of rightIcons" (click)="goTo(link.key)" class="menu-item">
                <span
                    class="icon {{ link.icon }}"
                    [tooltip]="link.label"
                    [tooltipTextColor]="getApplicationColor()"
                    tooltipColor="#fff"
                >
                </span>
            </div>
        </nav>
        <div class="user-help" [ngClass]="centerIcons?.length ? 'hide-if-necessary' : ''">
            <span
                class="icon icon-Aide"
                *ngIf="helpIsEnabled"
                (click)="openHelp()"
                [tooltip]="'Aide'"
                [tooltipTextColor]="getApplicationColor()"
                tooltipColor="#fff"
            ></span>
        </div>
    </div>
</div>
