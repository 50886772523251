import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { log } from './decorators/log.decorator';

/**
 *  service gerant l'utilisateur actuel dans l'application.
 */
@Injectable({
    providedIn: 'root'
})
export class NewsService {
    newsRead: Subject<number> = new Subject();
    allNewsRead: Subject<void> = new Subject();
    newsDeleted: Subject<number> = new Subject();
    numberNewsUnseen: Subject<number> = new Subject();

    constructor(private http: HttpClient) {}

    @log() getReadNews(search: string, startDate: Date, endDate: Date) {
        const params: any = {
            type: 'seen'
        };

        if (search) {
            params.search = search;
        }
        if (startDate) {
            params.startdate = startDate.toISOString();
        }
        if (endDate) {
            params.enddate = endDate.toISOString();
        }

        return this.http.get('/news/mine', { params });
    }

    @log() getUnreadNews(search: string, startDate: Date, endDate: Date) {
        const params: any = {
            type: 'notseen'
        };

        if (search) {
            params.search = search;
        }
        if (startDate) {
            params.startdate = startDate.toISOString();
        }
        if (endDate) {
            params.enddate = endDate.toISOString();
        }

        return this.http.get('/news/mine', { params });
    }

    @log() markAsRead(newsId: number) {
        return this.http.get('/news/' + newsId);
    }
    @log() markAllAsRead() {
        return this.http.post('/news/seen', {});
    }
    @log() hideNews(newsId: number) {
        return this.http.put('/news/' + newsId + '/hide', {});
    }
    @log() deleteNews(newsId: number) {
        return this.http.delete('/news/' + newsId);
    }

    emitNewsRead(newsId: number) {
        this.newsRead.next(newsId);
    }

    emitAllNewsRead() {
        this.allNewsRead.next();
    }

    emitNewsDeleted(newsId: number) {
        this.newsDeleted.next(newsId);
    }

    emitNumberNewsUnseen(newsCount: number) {
        this.numberNewsUnseen.next(newsCount);
    }
}
