import { Component, OnInit, Inject, NgModule } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';

import { UserService } from '../../services/user.service';
import { LoadingService } from '../../services/loading.service';

import { User } from '../../structures/user';
import { CommonModule, DatePipe } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppModule } from 'client-src/app/app.module';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-history',
    templateUrl: './user-history.component.html',
    styleUrls: ['./user-history.component.scss'],
    providers: [DatePipe]
})
export class UserHistoryComponent implements OnInit {
    subscriptions = new Subscription();

    user: User;
    endDate: string;
    startDate: string;
    creationDate: Date;
    connexions: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<UserHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { user: User; startDate: string; endDate: string },
        private userService: UserService,
        private loadingService: LoadingService
    ) {
        this.user = this.data.user;
    }

    ngOnInit() {
        if (this.data.startDate && this.data.endDate) {
            this.startDate = new Date(this.data.startDate).toISOString();
            this.endDate = new Date(this.data.endDate).toISOString();
        } else {
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1);
            this.startDate = startDate.toISOString();
            this.endDate = new Date().toISOString();
        }
        this.getHistory();
    }

    getHistory() {
        this.loadingService.startLoading('userHistory', 'getUserHistory');
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 0);
        this.subscriptions.add(
            this.userService.getUserHistory(this.user.id, start, end).subscribe(
                (data: any) => {
                    this.creationDate = new Date(data.inscriptionDate);
                    this.connexions = data.connections;
                    this.loadingService.stopLoading('userHistory', 'getUserHistory');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userHistory');
                }
            )
        );
    }

    onDateStartChange($event) {
        if ($event.target.value) {
            if ($event.type === 'keydown' && $event.key === 'Enter') {
                const temp = $event.target.value.split('/').reverse();
                this.startDate = new Date(temp).toISOString();
            } else {
                this.startDate = $event.target.value;
            }
            this.getHistory();
        }
    }
    onDateEndChange($event) {
        if ($event.target.value) {
            if ($event.type === 'keydown' && $event.key === 'Enter') {
                const temp = $event.target.value.split('/').reverse();
                this.endDate = new Date(temp).toISOString();
            } else {
                this.endDate = $event.target.value;
            }
            this.getHistory();
        }
    }

    getUserName() {
        return `${this.user.firstname} ${this.user.lastname.toUpperCase()}`;
    }

    getConnexionsCount() {
        if (this.connexions) {
            return `(${this.connexions.length} connexions)`;
        }
    }

    getCreationDate() {
        if (this.creationDate) {
            return this.creationDate.toLocaleDateString('fr-FR');
        }
    }

    getFormattedDate(connexion: string) {
        return new Date(connexion).toLocaleDateString('fr-FR');
    }

    getFormattedTime(connexion: string) {
        return new Date(connexion).toLocaleTimeString('fr-FR');
    }

    isLoading() {
        return this.loadingService.isLoading('userHistory');
    }

    /**
     * Ferme la fenêtre
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
