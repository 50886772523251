import { Injectable } from '@angular/core';

/**
 *  service gerant les urls des differents backend et frontend de l'app.
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config: any;

    setConfig(config: any) {
        this.config = config;
    }

    getConfig(): any {
        return this.config;
    }

    getEndPoint(): string {
        return this.config.endpoint.connect.backend;
    }

    getTrainingEndPoint(): string {
        return this.config.endpoint.training.backend;
    }

    getConnectFrontEndPoint(): string {
        return this.config.endpoint.connect.front;
    }

    getNotificationEndPoint(): string {
        return this.config.endpoint.notif;
    }
}
