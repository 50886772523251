<nav class="icon-container" [ngClass]="{ opened: isOpened() }">
    <div
        *ngFor="let link of centerIcons"
        (click)="goTo(link.key)"
        class="menu-item"
        [ngStyle]="{ 'background-color': getApplicationColor() }"
    >
        <span class="icon {{ link.icon }}"></span>
        <div class="numberNews" *ngIf="link.key == 'newsList' && numberNewsUnseen > 0">
            {{ numberNewsUnseen }}
        </div>
        <span class="title">{{ link.label }}</span>
    </div>
    <!-- <div
        class="menu-item menu-item_help"
        [ngStyle]="{ 'background-color': getApplicationColor() }"
        *ngIf="helpIsEnabled"
        (click)="openHelp()"
    >
        <span class="icon icon-Aide"></span>
        <span class="title">Aide</span>
    </div> -->
</nav>
