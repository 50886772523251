import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { NotificationService } from '../../services/notification.service';
import { ApplicationService } from '../../services/application.service';
import { IframeService } from '../../services/iframe.service';
import { LoginService } from '../../services/login.service';

import { Application } from '../../structures/application';

import { UntilDestroy } from '@ngneat/until-destroy';

/**
 *  component qui gere les popup liees aux notifications.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-notification-popup',
    templateUrl: './notification-popup.component.html',
    styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {
    /**
     * @param {any} notification la notification a ouvrir en popup.
     */
    notification: any;
    newNotificationReceived$: Subscription;
    applications: Array<Application>;

    constructor(
        private notificationService: NotificationService,
        private iframeService: IframeService,
        private loginService: LoginService,
        private applicationService: ApplicationService
    ) {}

    isOpened(): boolean {
        return this.iframeService.isOpenedComponent('NotificationPopupComponent');
    }

    /**
     *  ferme la popup des notifications.
     * @returns {void}
     */
    closePopup($event: Event): void {
        $event.stopImmediatePropagation();
        this.iframeService.closeComponent('NotificationPopupComponent');
    }

    closePopupWithoutPreventing() {
        if (this.iframeService.isOpenedComponent('NotificationPopupComponent')) {
            this.iframeService.closeComponent('NotificationPopupComponent');
        }
    }

    /**
     *  accede au nom de l'application demandee en parametre.
     * @param id l'identifiant de l'application a rechercher ici.
     * @returns {string} le nom de l'application recherchee.
     */
    getNotificationName(id: string) {
        for (const i in this.applications) {
            if (this.applications[i].app_id === id) {
                return this.applications[i].name;
            } else if (id === 'easiconnect') {
                return 'connect';
            }
        }

        return '';
    }

    /**
     *  accede a la couleur de l'application demandee en parametre.
     * @param id l'identifiant de l'application a rechercher ici.
     * @returns {string} la couleur de l'application recherchee.
     */
    getNotificationColor(id: string) {
        for (const i in this.applications) {
            if (this.applications[i].app_id === id) {
                return this.applications[i].color;
            } else if (id === 'easiconnect') {
                return '#035a77';
            }
        }

        return '';
    }

    /**
     *  accede a l'icone de l'application demandee en parametre.
     * @param id l'identifiant de l'application a rechercher ici.
     * @returns {string} le nom de l'icone de l'application recherchee.
     */
    getNotificationIcon(id: string) {
        for (const i in this.applications) {
            if (this.applications[i].app_id === id) {
                return this.applications[i].icon_font;
            } else if (id === 'easiconnect') {
                return 'icon-easi-control-line';
            }
        }

        return '';
    }

    goToNotification() {
        if (this.notification.url) {
            window.open(this.notification.url, '_blank');
        }
    }

    ngOnInit() {
        if (this.loginService.getUser()) {
            this.applicationService
                .getApplications()
                .subscribe((applications: Array<Application>) => {
                    this.applications = applications;
                });
        }

        this.newNotificationReceived$ = this.notificationService.newNotificationReceived.subscribe(
            (data: any) => {
                this.notification = data;
                this.notification.color = this.getNotificationColor(this.notification.app_id);
                this.notification.icon = this.getNotificationIcon(this.notification.app_id);
                this.notification.name = this.getNotificationName(this.notification.app_id);
                this.notification.date = new Date(this.notification.timecreated * 1000);
                this.iframeService.openComponent('NotificationPopupComponent');

                setTimeout(() => {
                    this.iframeService.closeComponent('NotificationPopupComponent');
                }, 8000);
            }
        );
    }
}
