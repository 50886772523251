import { ApplicationRef, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { UserComponent } from './components/user/user.component';

import { ApplicationComponent } from './components/application/application.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationEntryComponent } from './components/notification/notification-entry/notification-entry.component';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { NewsComponent } from './components/news/news.component';
import { NewsEntryComponent } from './components/news/news-entry/news-entry.component';
import { IconMobileComponent } from './components/icon-mobile/icon-mobile.component';
import { ChatPopupComponent } from './components/chat-popup/chat-popup.component';
import { LoadingComponent } from './components/loading/loading.component';
import { FlashMessageComponent } from './components/flash-message/flash-message.component';
import { HelpComponent } from './components/help/help.component';
import { NotificationsManagerComponent } from './components/notifications-manager/notifications-manager.component';

import { UserDetailsComponent } from './dialogs/user-details/user-details.component';
import { UserHistoryComponent } from './dialogs/user-history/user-history.component';
import { ChatComponent } from './dialogs/chat/chat.component';
import { ChatEntryComponent } from './dialogs/chat/chat-entry/chat-entry.component';
import { ErrorComponent } from './dialogs/error/error.component';
import { WarningComponent } from './dialogs/warning/warning.component';
import { LogoutComponent } from './dialogs/logout/logout.component';
import { HelpContentComponent } from './dialogs/help-content/help-content.component';

import { ConfigService } from './services/config.service';

import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { LogInterceptor } from './interceptors/log.interceptor';
import { EndpointInterceptor } from './interceptors/endpoint.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { AppFilterPipe } from './pipes/app-filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgClickOutsideDirective } from 'ng-click-outside2';

import { TooltipModule } from 'libs/uimm-tooltip/src/public-api';
import { DropdownModule } from 'libs/uimm-dropdown/src/public-api';
import { NotificationsFilterByAppPipe } from './pipes/notifications-filter-by-app.pipe';

@NgModule({
    declarations: [
        AppComponent,
        AppFilterPipe,
        SafeHtmlPipe,
        UserComponent,
        UserHistoryComponent,
        ApplicationComponent,
        NotificationComponent,
        HeaderComponent,
        NotificationEntryComponent,
        NotificationPopupComponent,
        ChatPopupComponent,
        IconMobileComponent,
        NewsComponent,
        NewsEntryComponent,
        ChatComponent,
        ChatEntryComponent,
        ErrorComponent,
        WarningComponent,
        HelpComponent,
        UserDetailsComponent,
        LogoutComponent,
        HelpContentComponent,
        UserHistoryComponent,
        LoadingComponent,
        FlashMessageComponent,
        NotificationsManagerComponent,
        NotificationsFilterByAppPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        InfiniteScrollModule,
        MatDialogModule,
        MatSelectModule,
        MatInputModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        TooltipModule,
        DropdownModule,
        NgClickOutsideDirective
    ],
    exports: [MatDialogModule, LoadingComponent],
    providers: [
        CookieService,
        MatNativeDateModule,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: []
})
export class AppModule {
    constructor(private configService: ConfigService) {}

    ngDoBootstrap(app) {
        const getConfig = new XMLHttpRequest();
        getConfig.onload = (data: any) => {
            const response = JSON.parse(data.target.response);
            if (response.profile) {
                this.getProfileConfigSetConfigAndBootstrap(response, app);
            } else {
                this.setConfigAndBootStrap(response, app);
            }
        };
        getConfig.open('get', `./assets/config/config.json?${Date.now()}`, true);
        getConfig.send();
    }

    private setConfigAndBootStrap(config: object, app: ApplicationRef): void {
        this.configService.setConfig(config);
        app.bootstrap(AppComponent);
    }

    private getProfileConfigSetConfigAndBootstrap(
        response: { profile: string },
        app: ApplicationRef
    ): void {
        const getProfileConfig = new XMLHttpRequest();

        getProfileConfig.onload = (data2: any) => {
            const profileResponse = JSON.parse(data2.target.response);
            this.setConfigAndBootStrap(
                {
                    ...response,
                    ...profileResponse
                },
                app
            );
        };
        getProfileConfig.open('get', `./assets/config/${response.profile}.json?${Date.now()}`);
        getProfileConfig.send();
    }
}
