import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ApplicationService } from './application.service';
import { ConfigService } from './config.service';
import { log } from './decorators/log.decorator';

import { Role } from '../structures/role';

/**
 *  service gerant l'utilisateur actuel dans l'application.
 */
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    /**
     * @param {any} user est l'utilisateur actuel.
     * @param {any} rightIcons les icones de droite relatif a l'utilisateur actuel.
     * @param {any} centerIcons les icones du centre relatif a l'utilisateur actuel.
     * @param {Array<any>} roles tableau stockant les roles d'un user.
     * @param {boolean} helpIsEnabled si l'aide est activee ou pas.
     * @param {Subject<string>} iconsLoaded emet un evt au chargement des icones
     */
    user: any;
    structures: any;
    rightIcons: any;
    centerIcons: any;
    helpIsEnabled: boolean;
    roles: Array<any> = [];
    additionalRoles: Array<any> = [];
    iconSetted = false;

    iconsLoaded: Subject<string> = new Subject();

    constructor(
        private http: HttpClient,
        private applicationService: ApplicationService,
        private configService: ConfigService
    ) {}

    /**
     *  nettoie l'utilisateur passe en parametre.
     * @param user l'utilisateur a nettoyer.
     * @returns {void}
     */
    clearUser(user: any): void {
        user = null;
    }

    /**
     * @returns {any} l'utilisateur actuel.
     */
    getUser(): any {
        return this.user;
    }

    getStructuresHistory(): any {
        return this.structures;
    }

    /**
     * @returns {any} le tableau de roles de l'user.
     */
    @log() getRoles(): Observable<any> {
        if (this.roles.length) {
            return new Observable((observer) => {
                observer.next(this.roles);
                observer.complete();
            });
        } else {
            return new Observable((observer) => {
                return this.http.get<Array<Role>>(`/roles`).subscribe((data: any) => {
                    this.roles = data;
                    this.roles = this.addIconsToRoles(this.roles);
                    this.roles = this.formatArrayForDropdown(this.roles);
                    observer.next(this.roles);
                    observer.complete();
                });
            });
        }
    }

    getAditionnalRoles(): Observable<any> {
        if (this.additionalRoles.length) {
            return new Observable((observer) => {
                observer.next(this.additionalRoles);
                observer.complete();
            });
        } else {
            return new Observable((observer) => {
                return this.http.get<Array<Role>>(`/additional_roles`).subscribe((data: any) => {
                    this.additionalRoles = data;
                    this.additionalRoles = this.addIconsToRoles(this.additionalRoles);
                    this.additionalRoles = this.formatArrayForDropdown(this.additionalRoles);
                    observer.next(this.additionalRoles);
                    observer.complete();
                });
            });
        }
    }

    /**
     *  l'utilisateur actuellement connecte sur la plateforme via le web service prevu a cet effet.
     * @returns {Observable<any>} l'observable qui gere la recuperation de l'user courant.
     */
    @log() getCurrentSession(): Observable<any> {
        const params = {};

        return this.http.get('/users/current', { params }).pipe(
            tap((data) => {
                if (data === 0) {
                    const search = new URLSearchParams(window.location.href.split('?')[1]);
                    if (search.get('unauthenticated') !== 'true') {
                        window.top.location.href =
                            this.configService.getConnectFrontEndPoint() + '/#!/login';
                    }
                } else {
                    this.initUser(data);
                }
            })
        );
    }

    getStructures(): Observable<any> {
        return this.http.get('/structures').pipe(
            tap((structuresList: any[]) => {
                this.structures = structuresList;
            })
        );
    }

    @log() logWithHub3e(user_id, token): Observable<any> {
        const params = {};

        return this.http.get(`/hub3e/login/${user_id}/${token}`, { params }).pipe(
            tap((data) => {
                window.parent.postMessage({ loggedWithHub3e: data }, '*');
            })
        );
    }

    /**
     *  fonction de deconnexion de l'utilisateur.
     * @returns {Observable<any>} l'observable qui gere la deconnexion.
     */
    logout(): Observable<any> {
        return this.http.post('/users/logout', {});
    }

    /**
     *  initialise l'utilisateur apres sa connexion.
     * @returns {void}
     */
    initUser(data: any): void {
        this.user = data;
        this.user.connectionStatus = this.user.defaultConnectionStatus;

        this.user.localStructure = this.structures
            .filter((structure) => structure.id === this.user.structureid)
            .map((structure) => structure.name);
    }

    /**
     *  gere la reception des permissions, des liens et des roles des utilisateurs. Remplit les routes contenues
     * dans les icones de droite, du centre et active aussi l'icone d'aide.
     * @returns {void}
     */
    setIconsForCurrentUser(icons): void {
        this.rightIcons = [];
        this.centerIcons = [];
        const currentAppId: string = this.applicationService.getCurrentApplication().app_id;
        if (
            currentAppId === 'easitraining' ||
            currentAppId === 'easiconnect' ||
            currentAppId === 'easilearning'
        ) {
            icons.center.push({
                key: 'newsList',
                icon: 'icon-News',
                label: 'News',
                permission: [
                    'nationalAdmin',
                    'localAdmin',
                    'internalTeacher',
                    'externalTeacher',
                    'siteTeacher',
                    'learner'
                ]
            });
        }

        if (icons.center) {
            for (const icon of icons.center) {
                for (const role of icon.permission) {
                    if (this.getUser().roles[role] && this.centerIcons.indexOf(icon) === -1) {
                        this.centerIcons.push(icon);
                    }
                }
            }
        }
        if (icons.right) {
            for (const icon of icons.right) {
                for (const role of icon.permission) {
                    if (this.getUser().roles[role] && this.rightIcons.indexOf(icon) === -1) {
                        this.rightIcons.push(icon);
                    }
                }
            }
        }
        if (icons.help === 'enabled') {
            this.helpIsEnabled = true;
        } else {
            this.helpIsEnabled = false;
        }

        if (!this.iconSetted) {
            this.iconSetted = true;
            this.iconsLoaded.next();
        }
    }

    @log() setDefaultConnectionStatus(defaultConnectionStatus) {
        return this.http.put(
            `/users/setDefaultConnectionStatus/${defaultConnectionStatus}`,
            undefined
        );
    }

    @log() unlinkAzure() {
        return this.http.put(`/users/unlink_azure`, undefined);
    }

    updateAvatar(newImageURL) {
        // Added timeStamp as a hack to force image refresh
        this.user.avatar = `${newImageURL}?${new Date().getTime()}`;
    }

    /**
     *  recupere la liste des icones de droite du bandeau.
     * @returns les icones de droite.
     */
    public getRightIconsForCurrentUser(): any {
        return this.rightIcons;
    }

    /**
     *  recupere la liste des icones du centre du bandeau.
     * @returns les icones du centre.
     */
    public getCenterIconsForCurrentUser(): any {
        return this.centerIcons;
    }

    /**
     *  recupere le booleen concernant l'icone d'aide.
     * @returns si l'aide doit etre affichee ou non.
     */
    public getHelpState(): any {
        return this.helpIsEnabled;
    }

    addIconsToRoles(roles) {
        for (const role of roles) {
            if (role.shortname === 'nationalAdmin') {
                role.icon = 'icon-adminnational';
            } else if (role.shortname === 'localAdmin') {
                role.icon = 'icon-adminlocal';
            } else if (role.shortname === 'nationalTeacher') {
                role.icon = 'icon-auteurnational';
            } else if (role.shortname === 'internalTeacher') {
                role.icon = 'icon-formateurinterne';
            } else if (role.shortname === 'externalTeacher') {
                role.icon = 'icon-formateurexterne';
            } else if (role.shortname === 'corporationTeacher') {
                role.icon = 'icon-FormateurEntreprise';
            } else if (role.shortname === 'siteTeacher') {
                role.icon = 'icon-formateur-site';
            } else if (role.shortname === 'tutor') {
                role.icon = 'icon-tuteurentreprise';
            } else if (role.shortname === 'learner') {
                role.icon = 'icon-apprenant';
            } else if (role.shortname === 'prospect') {
                role.icon = 'icon-apprenant-prospect';
            }

            if (role.shortname === 'accountManager') {
                role.icon = 'icon-gestionnairedecomptes';
            } else if (role.shortname === 'contentManager') {
                role.icon = 'icon-gestionnairecontenuspayants';
            } else if (role.shortname === 'validator') {
                role.icon = 'icon-valideur';
            } else if (role.shortname === 'externalCallManager') {
                role.icon = 'icon-Telephone';
            } else if (role.shortname === 'contentLocal') {
                role.icon = 'icon-local';
            } else if (role.shortname === 'contentNational') {
                role.icon = 'icon-national';
            } else if (role.shortname === 'contentCreator') {
                role.icon = 'icon-ajouter-contenus';
            }

            if (role.name === 'Référent pédagogique') {
                role.icon = 'icon-easi-training-line';
            }
        }
        // roles = roles.filter((role: Role) => {
        //     if (this.getUser().roles.nationalAdmin) {
        //         return true;
        //     } else {
        //         return role.shortname !== 'siteTeacher';
        //     }
        // });
        return roles;
    }

    formatArrayForDropdown(roles): Array<any> {
        return roles.map((item) => ({
            ...item,
            title: item.name,
            key: item.shortname
        }));
    }
}
