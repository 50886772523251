<div class="news-container" [ngClass]="{ 'read-news': read, 'unread-news': !read }">
    <div class="news-data">
        <span class="news-title">
            <span class="icon-News"></span>
            <span class="news-title_title">{{ getNewsTitle() }}</span>
            <span class="news-author"> {{ getNewsAuthor() }}</span>
        </span>
        <div class="right-element">
            <span class="news-date" *ngIf="canShowNewsDate(getNewsDate())"
                >{{ getNewsDate() | date: 'dd/MM/yyyy' }} à
                {{ getNewsDate() | date: 'HH:mm:ss' }}</span
            >
            <span class="icon-bin" (click)="deleteNews()"></span>
            <span
                class="icon-down"
                (click)="toggleNews()"
                [ngClass]="{ opened: isNewsOpened() }"
            ></span>
        </div>
    </div>
    <div
        class="news-body"
        id="news-description"
        [innerHTML]="getNewsDescription()"
        [ngClass]="{ opened: isNewsOpened() }"
    ></div>
</div>
