import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Subscription, Subject, Observable } from 'rxjs';

import { ApplicationService } from '../../services/application.service';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { GroupService } from '../../services/group.service';
import { SiteService } from '../../services/site.service';
import { IframeService } from '../../services/iframe.service';
import { FlashMessageService } from '../../services/flash-message.service';
import { LoadingService } from '../../services/loading.service';

import { User } from '../../structures/user';
import { Group } from '../../structures/group';
import { Site } from '../../structures/site';
import { Application } from '../../structures/application';

import { Structure } from '../../structures/structure';

import { CookieService } from 'ngx-cookie-service';
import { UntilDestroy } from '@ngneat/until-destroy';

/**
 *  fenetre de dialogue pour la deconnexion.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
    subscriptions = new Subscription();

    roles: Array<any>;
    additionalRoles: Array<any>;

    emitOpenWarningDialog: Subject<string> = new Subject();
    getOpenWarningDialog: Subject<boolean> = new Subject();
    emitDeleteUser: Subject<User> = new Subject();

    emitOpenTutorUsers: Subject<any> = new Subject();
    getOpenTutorUsers: Subject<any> = new Subject();

    editionMode = false;

    tags: Array<any> = [];

    user: User;
    userRoles: Array<any> = [];
    learners: Array<User> = [];
    initialTutorUsers: Array<User> = [];
    tutorUsers: Array<User> = [];

    initialTutorGroups: Array<User> = [];
    tutorGroups: Array<User> = [];

    groups: Array<Group>;
    learnerGroups: Array<Group>;
    teacherGroups: Array<Group>;
    sites: Array<Site>;
    corporations: Array<Site>;

    creationDateStart: Date;
    creationDateEnd: Date;

    structures: Array<Structure> = [];
    applications: Array<Application>;

    constructor(
        private dialogRef: MatDialogRef<UserDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) private userId: number,
        private applicationService: ApplicationService,
        private loginService: LoginService,
        private userService: UserService,
        private groupService: GroupService,
        private siteService: SiteService,
        private iframeService: IframeService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService,
        private cookieService: CookieService
    ) {}

    ngOnInit() {
        if (this.userId > 0) {
            this.loadingService.startLoading('userDetails', 'getUser');
            this.subscriptions.add(
                this.userService.getUser(this.userId).subscribe(
                    (data: User) => {
                        this.formatUserData(data);
                        this.setUserRoles().subscribe(() => {
                            if (this.canEditUser()) {
                                this.getAdditionalRoleAccess();
                            }
                        });
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('userDetails', 'getUser');
                    }
                )
            );
        } else {
            if (this.userId === 0) {
                this.editionMode = true;
                this.user = {
                    firstname: '',
                    lastname: '',
                    sites: [],
                    groups: [],
                    tags: [],
                    roles: {
                        learner: true,
                        tutor: false,
                        nationalAdmin: false
                    },
                    additionalRoles: {}
                };
                this.setUserRoles().subscribe(() => {
                    if (this.loginService.getUser().roles.nationalAdmin) {
                        this.loadingService.startLoading('userDetails', 'getStructures');
                        this.subscriptions.add(
                            this.loginService.getStructures().subscribe(
                                (data: Array<Structure>) => {
                                    this.structures = data.map((structure: Structure) => {
                                        return {
                                            title: structure.name,
                                            key: structure.id,
                                            shortname: structure.shortname,
                                            selected:
                                                structure.id ===
                                                    +this.cookieService.get('structureId') &&
                                                structure.name ===
                                                    this.cookieService.get('structureName')
                                        };
                                    });
                                    this.updateStructure();
                                    if (this.canEditUser()) {
                                        this.getAdditionalRoleAccess();
                                    }
                                    this.loadingService.stopLoading('userDetails', 'getStructures');
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading('userDetails', 'getStructures');
                                }
                            )
                        );
                    } else {
                        this.user.structureid = this.loginService.getUser().structureid;
                        this.user.localStructure = this.loginService.getUser().localStructure;
                        this.updateStructure();
                        if (this.canEditUser()) {
                            this.getAdditionalRoleAccess();
                        }
                    }
                });
            } else if (this.userId === -1) {
                this.editionMode = true;
                this.user = {
                    firstname: '',
                    lastname: '',
                    sites: [],
                    groups: [],
                    tags: [],
                    structureid: 0,
                    localStructure: 'Administration nationale',
                    roles: {
                        learner: false,
                        tutor: false,
                        nationalAdmin: true
                    }
                };
                this.setUserRoles().subscribe(() => {
                    if (this.canEditUser()) {
                        this.getAdditionalRoleAccess();
                    }
                });
            }
        }
        this.loadingService.startLoading('userDetails', 'getTags');
        this.subscriptions.add(
            this.userService.getTags().subscribe(
                (data: Array<any>) => {
                    this.tags = data.map((tag: any) => {
                        return {
                            ...tag,
                            icon: 'icon-easi-training-line'
                        };
                    });
                    this.loadingService.stopLoading('userDetails', 'getTags');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'getTags');
                }
            )
        );
        this.getOpenWarningDialog.subscribe((status: boolean) => {
            if (status) {
                this.subscriptions.add(
                    this.userService.resetTempPassword(this.user.id).subscribe(() => {
                        this.flashMessageService.flash(
                            "Le mot de passe temporaire de l'utilisateur a été réinitialisé"
                        );
                        this.subscriptions.add(
                            this.userService.getUser(this.userId).subscribe(
                                (data: User) => {
                                    this.formatUserData(data);
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading('userDetails', 'getUser');
                                }
                            )
                        );
                    })
                );
            }
        });
    }

    isNationalAdmin() {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    updateStructure() {
        if (this.getStructureSelected()) {
            this.user.structureid = this.getStructureSelected().key;
            this.user.localStructure = this.getStructureSelected().title;
        } else {
            this.user.structureid = 0;
            this.user.localStructure = 'Administration nationale';
        }
        if (this.user.structureid) {
            if (
                !this.loginService.getUser().roles.tutor &&
                !this.loginService.getUser().roles.corporationTeacher &&
                !this.loginService.getUser().roles.siteTeacher
            ) {
                this.getSites();
                this.getGroups();
                this.getCorporations();
            }
            if (this.canEditUser()) {
                this.getApplicationAccess();
                this.getAdditionalRoleAccess();
            }
        }
    }

    getStructureSelected() {
        if (this.structures.length) {
            return this.structures.filter((structure: Structure) => {
                return structure.selected;
            })[0];
        } else {
            return {
                key: this.user.structureid,
                title: this.user.localStructure,
                shortname: this.user.localStructureShortname
            };
        }
    }

    isUserInSite(site: Site) {
        for (const i in this.user.sites) {
            if (this.user.sites[i].id === site.id) {
                return true;
            }
        }
        return false;
    }

    isUserInGroup(group: Group) {
        for (const i in this.user.groups) {
            if (this.user.groups[i].id === group.id) {
                return true;
            }
        }
        return false;
    }

    getSites() {
        if (this.user.structureid === 0) {
            this.sites = [];
            return;
        }
        this.loadingService.startLoading('userDetails', 'getSites');
        this.subscriptions.add(
            this.siteService.getSites(this.user.structureid).subscribe(
                (data: Array<Site>) => {
                    this.sites = data.map((site: Site) => {
                        return {
                            title: site.name,
                            key: site.id,
                            selected: this.isUserInSite(site)
                        };
                    });
                    this.loadingService.stopLoading('userDetails', 'getSites');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'getSites');
                }
            )
        );
    }

    /**
     * retourne true si un site doit obligatoireement être sélectionné pour l'utilisateur
     */
    isSiteMandatory(): boolean {
        return this.isSelectedRole('learner') || this.isSelectedRole('siteTeacher');
    }

    getSitesLabelDefaultColor() {
        if (
            this.isSiteMandatory() &&
            this.sites &&
            this.sites.find((site) => site.selected) === undefined
        ) {
            return '#ff7200';
        }
        return undefined;
    }

    getSitesLabel() {
        return this.isSiteMandatory() ? 'Sites*' : 'Sites';
    }

    getCorporations() {
        if (this.user.structureid === 0) {
            this.corporations = [];
            return;
        }
        this.loadingService.startLoading('userDetails', 'getCorporations');
        this.subscriptions.add(
            this.siteService.getCorporations(this.user.structureid).subscribe(
                (data: Array<Site>) => {
                    this.corporations = data.map((site: Site) => {
                        return {
                            title: site.name,
                            key: site.id,
                            selected: this.isUserInSite(site)
                        };
                    });
                    this.loadingService.stopLoading('userDetails', 'getCorporations');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'getCorporations');
                }
            )
        );
    }

    getGroups() {
        if (this.user.structureid === 0) {
            this.groups = [];
            return;
        }
        this.loadingService.startLoading('userDetails', 'getGroups');
        this.subscriptions.add(
            this.groupService
                .getGroups({
                    structureid: this.user.structureid
                })
                .subscribe(
                    (data: Array<Group>) => {
                        this.learnerGroups = data
                            .filter((group: Group) => group.type === 'learner')
                            .map((group: Group) => {
                                return {
                                    title: group.name,
                                    key: group.id,
                                    selected: this.isUserInGroup(group)
                                };
                            });
                        this.teacherGroups = data
                            .filter((group: Group) => group.type === 'teacher')
                            .map((group: Group) => {
                                return {
                                    title: group.name,
                                    key: group.id,
                                    selected: this.isUserInGroup(group)
                                };
                            });
                        this.loadingService.stopLoading('userDetails', 'getGroups');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('userDetails', 'getGroups');
                    }
                )
        );
    }

    getUserSitesCount() {
        let count = 0;
        this.user.sites.map((site) => {
            if (!site.isCorporation) {
                count++;
            }
        });
        return count;
    }

    getUserCorporationsCount() {
        let count = 0;
        this.user.sites.map((site) => {
            if (site.isCorporation) {
                count++;
            }
        });
        return count;
    }

    getUserGroups() {
        return this.user.groups.filter((group) => group.type !== 'role');
    }

    getUserGroupsCount() {
        return this.getUserGroups().length;
    }

    isYpareo() {
        if (this.user && this.user.externalID) {
            return (
                this.user.externalID.charAt(0).toLowerCase() === 'a' ||
                this.user.externalID.charAt(0).toLowerCase() === 'f'
            );
        }
    }

    getUserYpareoID() {
        if (this.user && this.user.externalID) {
            return this.user.externalID;
        }
    }

    getRoleIcon(): string {
        for (const i in this.userRoles) {
            if (this.userRoles[i].selected) {
                return this.userRoles[i].icon;
            }
        }
    }

    getStructureIcon() {
        if (this.user.localStructure) {
            return 'icon-local';
        } else {
            return 'icon-national';
        }
    }

    getUserFullname() {
        return this.user.firstname + ' ' + this.user.lastname.toUpperCase();
    }

    getUserStructure() {
        if (this.user.localStructure) {
            return this.user.localStructure;
        } else {
            return 'Administration nationale';
        }
    }

    getUsername() {
        return this.user.username;
    }

    getUserEmail() {
        if (this.user.email) {
            return this.user.email;
        } else {
            return 'Aucune adresse email renseignée';
        }
    }

    isEmailVerified() {
        if (this.user.emailverified) {
            return this.user.emailverified.value;
        }
    }

    showTempPassword() {
        return this.user.passwordtemp && (!this.user.email || this.isEmailVerified());
    }

    getUserTempPassword() {
        if (this.user.passwordtemp) {
            return this.user.passwordtemp;
        }
    }

    getUserCGU() {
        if (this.user.cgu) {
            return this.user.cgu.value;
        }
    }

    hasReminder() {
        return !!this.user.cgu_validation_reminder_mail;
    }

    showResetTempPassword() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.internalTeacher
        );
    }

    resetTempPassword() {
        if (this.user.email && !this.isEmailVerified()) {
            this.emitOpenWarningDialog.next(
                "L'adresse email de cet utilisateur n'est pas validée, elle sera supprimée si vous rétablissez le mot de passe temporaire"
            );
        } else {
            this.subscriptions.add(
                this.userService.resetTempPassword(this.user.id).subscribe(() => {
                    this.flashMessageService.flash(
                        "Le mot de passe temporaire de l'utilisateur a été réinitialisé"
                    );
                    this.subscriptions.add(
                        this.userService.getUser(this.userId).subscribe(
                            (data: User) => {
                                this.formatUserData(data);
                            },
                            (error: HttpErrorResponse) => {
                                this.loadingService.stopLoading('userDetails', 'getUser');
                            }
                        )
                    );
                })
            );
        }
    }

    resendValidationMail() {
        if (this.editionMode) {
            return;
        }
        this.subscriptions.add(
            this.userService.resendValidationMail(this.user.id).subscribe(() => {
                this.flashMessageService.flash(
                    "L'email de validation a été renvoyé à l'utilisateur"
                );
            })
        );
    }

    showDateInfo() {
        return this.user.startaccount || this.user.endaccount;
    }

    isCurrentUserNationalAdmin() {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    goToHelp() {
        this.iframeService.isOpenedComponent('HelpComponent')
            ? this.iframeService.closeComponent('HelpComponent')
            : this.iframeService.openComponent('HelpComponent');
    }

    setUserRoles() {
        return new Observable((observer) => {
            this.userRoles = [];
            this.loginService.getRoles().subscribe((roles: Array<any>) => {
                this.roles = roles;
                for (const role of roles) {
                    this.userRoles.push({
                        ...role,
                        selected: this.user && this.user.roles[role.shortname]
                    });
                }
                observer.next();
                observer.complete();
            });
        });
    }

    getRoleLabel(shortname: string) {
        return this.roles.filter((role) => role.shortname === shortname)[0].name;
    }

    isSelectedRole(role: string) {
        for (const i in this.userRoles) {
            if (this.userRoles[i].key === role && this.userRoles[i].selected) {
                return true;
            }
        }
        return false;
    }

    isSelectedTag(tag: any) {
        for (const i in this.user.tags) {
            if (this.user.tags[i].id === tag.id) {
                return true;
            }
        }
        return false;
    }

    selectTag(tag: any) {
        let found = -1;
        for (const i in this.user.tags) {
            if (this.user.tags[i].id === tag.id) {
                found = +i;
            }
        }
        if (found === -1) {
            this.user.tags.push(tag);
        } else {
            this.user.tags.splice(found, 1);
        }
    }

    selectRole(role: string) {
        if (role === 'tutor') {
            this.sites.map((site) => (site.selected = false));
            this.corporations.map((corp) => (corp.selected = false));
        }

        if (role !== 'learner' && role !== 'prospect') {
            this.user.FI = null;
            this.user.trainingtype = null;
        } else {
            if (this.user.FI === null) {
                this.user.FI = true;
                this.user.trainingtype = 'FI';
            }
        }

        for (const i in this.userRoles) {
            if (this.userRoles[i].key === role) {
                this.userRoles[i].selected = true;
            } else {
                this.userRoles[i].selected = false;
            }
        }
        for (const i in this.applications) {
            if (
                this.applications[i].app_id === 'easilearning' ||
                this.applications[i].app_id === 'easitraining' ||
                this.applications[i].app_id === 'easicloud' ||
                this.applications[i].app_id ===
                    'easi_ncv2p_pilot-' + this.getStructureSelected().shortname ||
                this.applications[i].app_id ===
                    'easi_ncv2_' + this.getStructureSelected().shortname ||
                this.applications[i].app_id === 'easicall' ||
                this.applications[i].app_id === 'easiforum' ||
                this.applications[i].app_id === 'easimedia' ||
                this.applications[i].app_id === 'easichat'
            ) {
                this.applications[i].selected = this.hasApplicationsAccess(this.applications[i]);
            }
        }
        this.getAdditionalRoleAccess();

        for (const i in this.additionalRoles) {
            if (!this.hasAdditionalRoleAccess(this.additionalRoles[i])) {
                this.additionalRoles[i].selected = false;
            }
        }
    }

    showRole(role: string) {
        switch (role) {
            case 'learner':
            case 'prospect': {
                return this.userId !== -1 && !this.user.roles.nationalAdmin;
            }
            case 'internalTeacher':
            case 'externalTeacher':
            case 'tutor':
            case 'corporationTeacher': {
                return (
                    this.userId !== -1 &&
                    !this.user.roles.nationalAdmin &&
                    (this.loginService.getUser().roles.localAdmin ||
                        this.loginService.getUser().roles.nationalAdmin)
                );
            }
            case 'siteTeacher':
            case 'localAdmin': {
                return (
                    this.userId !== -1 &&
                    !this.user.roles.nationalAdmin &&
                    this.loginService.getUser().roles.nationalAdmin
                );
            }
            case 'nationalTeacher':
                return (
                    this.userId !== -1 &&
                    !this.user.roles.nationalAdmin &&
                    this.loginService.getUser().roles.nationalAdmin &&
                    this.getStructureSelected() &&
                    this.getStructureSelected().shortname === 'N4S'
                );

            case 'nationalAdmin': {
                return this.userId === -1 || this.user.roles.nationalAdmin;
            }
        }
    }

    showLearnerRoles() {
        for (const i in this.userRoles) {
            if (
                (this.userRoles[i].key === 'learner' || this.userRoles[i].key === 'prospect') &&
                this.userRoles[i].selected
            ) {
                return true;
            }
        }
        return false;
    }

    toggleFI() {
        if (this.user.FI !== true) {
            this.user.FI = true;
        } else {
            this.user.FI = undefined;
        }
    }

    toggleFC() {
        if (this.user.FI !== false) {
            this.user.FI = false;
        } else {
            this.user.FI = undefined;
        }
    }

    getFIFCSelectorStyles() {
        for (const i in this.userRoles) {
            if (
                this.userRoles[i].key === 'learner' &&
                this.userRoles[i].selected &&
                this.user.FI === undefined
            ) {
                return { color: '#ff7200' };
            }
        }
    }

    showTeacherRoles() {
        return !this.showLearnerRoles() && !this.showTutorRoles();
    }

    showAdditionalRoles() {
        for (const i in this.userRoles) {
            if (
                (this.userRoles[i].key === 'internalTeacher' ||
                    this.userRoles[i].key === 'externalTeacher') &&
                this.userRoles[i].selected
            ) {
                return true;
            }
        }
        return false;
    }

    showTutorRoles() {
        for (const i in this.userRoles) {
            if (this.userRoles[i].key === 'tutor') {
                return this.userRoles[i].selected;
            }
        }
        return false;
    }

    getAdditionnalRoleLabel() {
        for (const i in this.userRoles) {
            if (
                (this.userRoles[i].key === 'learner' || this.userRoles[i].key === 'prospect') &&
                this.userRoles[i].selected
            ) {
                return 'Type de formation*';
            } else if (this.userRoles[i].key === 'tutor' && this.userRoles[i].selected) {
                return 'Apprenant(s) lié(s)';
            } else if (
                (this.userRoles[i].key === 'internalTeacher' ||
                    this.userRoles[i].key === 'externalTeacher') &&
                this.userRoles[i].selected
            ) {
                return 'Rôles complémentaires';
            }
        }
    }

    openTutorUsersDialog(viewOnly: boolean) {
        const params = {
            viewOnly: viewOnly,
            tutorUsers: this.tutorUsers,
            tutorGroups: this.tutorGroups,
            structureid: this.getStructureSelected().key
        };

        this.emitOpenTutorUsers.next(params);
        this.subscriptions.add(
            this.getOpenTutorUsers.subscribe((data) => {
                this.tutorUsers = data.users;
                this.tutorGroups = data.groups;
            })
        );
    }

    getFIStatus() {
        if (this.user.roles.learner || this.user.roles.prospect) {
            if (this.user.FI) {
                return '(Formation initiale)';
            } else {
                return '(Formation Continue)';
            }
        }
    }

    getUserTags() {
        if (!this.user.tags.length) {
            return;
        }
        let tags = '(';
        for (const i in this.user.tags) {
            if (this.user.tags[i]) {
                tags += this.user.tags[i].name + ', ';
            }
        }
        return tags.slice(0, tags.length - 2) + ')';
    }

    getApplicationColor(): string {
        const currentApp = this.applicationService.getCurrentApplication();

        if (currentApp && currentApp.color) {
            return currentApp.color;
        } else {
            return '#424242';
        }
    }

    getTooltipColor(): string {
        const currentApp = this.applicationService.getCurrentApplication();

        if (currentApp && currentApp.app_id) {
            return currentApp.app_id;
        } else {
            return '#424242';
        }
    }

    getApplicationAccess() {
        this.loadingService.startLoading('userDetails', 'getApplicationAccess');
        this.subscriptions.add(
            this.userService.getApplicationAccess(this.user.structureid, this.user.id).subscribe(
                (data: any) => {
                    this.applications = [];
                    for (const i in data.applications) {
                        if (
                            data.applications[i].app_id === 'easilearning' ||
                            data.applications[i].app_id === 'easitraining' ||
                            data.applications[i].app_id === 'easicloud' ||
                            data.applications[i].app_id ===
                                'easi_ncv2p_pilot-' + this.getStructureSelected().shortname ||
                            data.applications[i].app_id ===
                                'easi_ncv2_' + this.getStructureSelected().shortname ||
                            data.applications[i].app_id === 'easicall' ||
                            data.applications[i].app_id === 'easiforum' ||
                            data.applications[i].app_id === 'easimedia' ||
                            data.applications[i].app_id === 'easichat'
                        ) {
                            data.applications[i].selected = this.hasApplicationsAccess(
                                data.applications[i]
                            );
                            this.applications.push(data.applications[i]);
                        }
                    }
                    const indexNCV2 = this.applications.findIndex(
                        (application) =>
                            application.app_id ===
                                'easi_ncv2p_pilot-' + this.getStructureSelected().shortname ||
                            application.app_id ===
                                'easi_ncv2_' + this.getStructureSelected().shortname
                    );
                    if (indexNCV2 !== -1) {
                        const NCV2 = this.applications.splice(indexNCV2, 1)[0];
                        const indexNCV1 = this.applications.findIndex(
                            (application) => application.app_id === 'easicloud'
                        );
                        this.applications.splice(indexNCV1 + 1, 0, NCV2);
                    }
                    this.loadingService.stopLoading('userDetails', 'getApplicationAccess');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'getApplicationAccess');
                }
            )
        );
    }

    getApplicationIcon(app_id: string) {
        if (app_id === 'easitraining') {
            return 'icon-formateurinterne';
        } else if (
            app_id === 'easicloud' ||
            app_id === 'easi_ncv2p_pilot-' + this.getStructureSelected().shortname ||
            app_id === 'easi_ncv2_' + this.getStructureSelected().shortname
        ) {
            return 'icon-easi-cloud-line';
        } else if (app_id === 'easicall') {
            return 'icon-easi-call-line';
        } else if (app_id === 'easiforum') {
            return 'icon-easi-forum-line';
        } else if (app_id === 'easimedia') {
            return 'icon-easi-media-line';
        } else if (app_id === 'easichat') {
            return 'icon-Messages';
        } else if (app_id === 'easilearning') {
            return 'icon-easi-training-line';
        } else {
            return '';
        }
    }

    hasApplicationsAccess(application: Application) {
        if (this.user) {
            for (const i in application.users) {
                if (application.users[i].id === this.user.id) {
                    if (application.users[i].allow === true) {
                        return true;
                    } else if (application.users[i].allow === false) {
                        return false;
                    }
                }
            }

            for (const i in this.userRoles) {
                if (this.userRoles[i].selected) {
                    for (const k in application.roles) {
                        if (application.roles[k].role === this.userRoles[i].key) {
                            if (application.roles[k].allow === true) {
                                return true;
                            } else if (application.roles[k].allow === false) {
                                return false;
                            }
                        }
                    }
                    for (const l in application.general.roles) {
                        if (application.general.roles[l].role === this.userRoles[i].key) {
                            if (application.general.roles[l].allow === true) {
                                return true;
                            } else if (application.general.roles[l].allow === false) {
                                return false;
                            }
                        }
                    }
                }
            }

            if (application.structure.allow === true) {
                return true;
            } else if (application.structure.allow === false) {
                return false;
            }

            if (application.general.default.allow === true) {
                return true;
            } else if (application.general.default.allow === false) {
                return false;
            }

            return false;
        }
    }

    isApplicationLocked(application: Application) {
        for (const i in this.applications) {
            if (application.id === this.applications[i].id) {
                if (this.applications[i].general.default.locked === true) {
                    return true;
                }
                if (this.applications[i].structure.locked === true) {
                    return true;
                }
                for (const j in this.applications[i].general.roles) {
                    if (this.applications[i].general.roles[j]) {
                        for (const k in this.userRoles) {
                            if (
                                this.applications[i].general.roles[j].role ===
                                    this.userRoles[k].key &&
                                this.userRoles[k].selected
                            ) {
                                if (this.applications[i].general.roles[j].locked === true) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                for (const j in this.applications[i].roles) {
                    if (this.applications[i].roles[j]) {
                        for (const k in this.userRoles) {
                            if (
                                this.applications[i].roles[j].role === this.userRoles[k].key &&
                                this.userRoles[k].selected
                            ) {
                                if (this.applications[i].roles[j].locked === true) {
                                    return true;
                                }
                            }
                        }
                    }
                }
                for (const l in this.applications[i].users) {
                    if (this.applications[i].users[l].id === this.user.id) {
                        if (this.applications[i].users[l].locked === true) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    toggleApplication(application: Application) {
        if (!this.isApplicationLocked(application)) {
            application.selected = !application.selected;
            if (application.app_id === 'easicall' && !application.selected) {
                this.userRoles.filter(
                    (roles) => roles.key === 'externalCallManager'
                )[0].selected = false;
            }
        }
    }

    getApplicationTooltip(application: Application) {
        if (application.selected && this.isApplicationLocked(application)) {
            return `L'accès à ${application.name} a été forcé par un administrateur national`;
        }
        if (!application.selected && this.isApplicationLocked(application)) {
            return `L'accès à ${application.name} a été interdit par un administrateur national`;
        }
        if (application.selected && !this.isApplicationLocked(application)) {
            return `Retirer l'accès à ${application.name}`;
        }
        if (!application.selected && !this.isApplicationLocked(application)) {
            return `Donner l'accès à ${application.name}`;
        }
    }

    getAdditionalRoleAccess() {
        this.loadingService.startLoading('userDetails', 'getAdditionalRoleAccess');
        this.subscriptions.add(
            this.userService.getAdditionalRoleAccess(this.user.structureid).subscribe(
                (data: any) => {
                    this.additionalRoles = [];
                    for (const additionalRole of data) {
                        additionalRole.selected = this.hasAdditionalRoleAccess(
                            additionalRole,
                            false
                        );
                        this.additionalRoles.push(additionalRole);
                    }
                    this.loadingService.stopLoading('userDetails', 'getAdditionalRoleAccess');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'getAdditionalRoleAccess');
                }
            )
        );
    }

    hasAdditionalRoleAccess(additionalRole: any, skipUsers?: boolean) {
        if (this.user) {
            if (!skipUsers) {
                for (const l in additionalRole.users) {
                    if (additionalRole.users[l].id === this.user.id) {
                        if (additionalRole.users[l].allow !== null) {
                            return additionalRole.users[l].allow;
                        }
                    }
                }
            }

            for (const role of additionalRole.roles) {
                if (
                    this.userRoles.filter((userRole) => userRole.shortname === role.role)[0]
                        .selected &&
                    role.allow !== null
                ) {
                    return role.allow;
                }
            }
            for (const generalRole of additionalRole.general.roles) {
                if (
                    this.userRoles.filter((userRole) => userRole.shortname === generalRole.role)[0]
                        .selected &&
                    generalRole.allow !== null
                ) {
                    return generalRole.allow;
                }
            }
            if (additionalRole.structure.allow !== null) {
                return additionalRole.structure.allow;
            }
            return additionalRole.general.default.allow;
        }
    }

    isAdditionalRoleLocked(additionalRole: any) {
        for (const l in additionalRole.users) {
            if (additionalRole.users[l].id === this.user.id) {
                if (additionalRole.users[l].locked !== null) {
                    return additionalRole.users[l].locked;
                }
            }
        }
        for (const role of additionalRole.roles) {
            if (
                this.userRoles.filter((userRole) => userRole.shortname === role.role)[0].selected &&
                role.locked !== null
            ) {
                return role.locked;
            }
        }
        for (const generalRole of additionalRole.general.roles) {
            if (
                this.userRoles.filter((userRole) => userRole.shortname === generalRole.role)[0]
                    .selected &&
                generalRole.locked !== null
            ) {
                return generalRole.locked;
            }
        }
        if (additionalRole.structure.locked !== null) {
            return additionalRole.structure.locked;
        }
        return additionalRole.general.default.locked;
    }

    toggleAdditionalRole(additionalRole: any) {
        if (!this.isAdditionalRoleLocked(additionalRole)) {
            additionalRole.selected = !additionalRole.selected;
        }
    }

    getAdditionalRoleTooltip(additionalRole: any) {
        if (additionalRole.selected && this.isAdditionalRoleLocked(additionalRole)) {
            return `Le rôle ${additionalRole.name} a été forcé par un administrateur national`;
        }
        if (!additionalRole.selected && this.isAdditionalRoleLocked(additionalRole)) {
            return `Le rôle ${additionalRole.name} a été interdit par un administrateur national`;
        }
        if (additionalRole.selected && !this.isAdditionalRoleLocked(additionalRole)) {
            return `Retirer le rôle ${additionalRole.name}`;
        }
        if (!additionalRole.selected && !this.isAdditionalRoleLocked(additionalRole)) {
            return `Donner le rôle ${additionalRole.name}`;
        }
    }

    canEditUser() {
        if (this.loginService.getUser()) {
            return (
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().additionalRoles.accountManager
            );
        }
        return false;
    }

    canCreateOrEditUser() {
        if (!this.user.firstname) {
            return false;
        }
        if (!this.user.lastname) {
            return false;
        }
        if (!this.user.username) {
            return false;
        }
        if (!this.user.structureid && !this.user.roles.nationalAdmin) {
            return false;
        }
        if (this.isSelectedRole('learner') && this.user.FI === undefined) {
            return false;
        }
        if (this.isSelectedRole('learner') || this.isSelectedRole('siteTeacher')) {
            if (this.sites.filter((site) => site.selected).map((site) => site.key).length === 0) {
                return false;
            }
        }
        return true;
    }

    formatUser() {
        for (const i in this.userRoles) {
            if (this.userRoles[i].selected) {
                this.user.roles[this.userRoles[i].key] = true;
            } else {
                this.user.roles[this.userRoles[i].key] = false;
            }
        }
        for (const i in this.additionalRoles) {
            if (this.user.additionalRoles) {
                if (this.additionalRoles[i].selected) {
                    this.user.additionalRoles[this.additionalRoles[i].shortname] = true;
                } else {
                    this.user.additionalRoles[this.additionalRoles[i].shortname] = false;
                }
            }
        }
        if (this.sites && this.corporations) {
            this.user.sites = this.sites
                .filter((site) => site.selected)
                .concat(this.corporations.filter((corporation) => corporation.selected))
                .map((site) => site.key);
        }
        if (this.user.roles.learner || (this.user.roles.prospect && this.learnerGroups)) {
            this.user.groups = this.learnerGroups
                .filter((group) => group.selected)
                .map((group) => group.key);
        } else if (this.teacherGroups) {
            this.user.groups = this.teacherGroups
                .filter((group) => group.selected)
                .map((group) => group.key);
        }
        if (this.creationDateStart) {
            this.creationDateStart.setHours(0, 0, 0, 0);
            this.user.startaccount = this.creationDateStart.toISOString();
        } else {
            this.user.startaccount = undefined;
        }
        if (this.creationDateEnd) {
            this.creationDateEnd.setHours(23, 59, 59, 0);
            this.user.endaccount = this.creationDateEnd.toISOString();
        } else {
            this.user.endaccount = undefined;
        }
        this.user.externalid = this.user.externalID;
    }

    formatUserData(user: User) {
        this.user = user;
        if (this.user.startaccount) {
            this.creationDateStart = new Date(this.user.startaccount);
        }
        if (this.user.endaccount) {
            this.creationDateEnd = new Date(this.user.endaccount);
        }
        this.loadingService.startLoading('userDetails', 'getStructures');
        this.subscriptions.add(
            this.loginService.getStructures().subscribe(
                (structures: Array<Structure>) => {
                    this.structures = structures.map((structure: Structure) => {
                        return {
                            title: structure.name,
                            key: structure.id,
                            shortname: structure.shortname,
                            selected: this.user && structure.id === this.user.structureid
                        };
                    });
                    console.log(this.structures);
                    if (this.user.roles.tutor) {
                        this.loadingService.startLoading('userDetails', 'getTutorUsers');
                        this.subscriptions.add(
                            this.userService.getTutorUsers(this.user.id).subscribe(
                                (users: Array<User>) => {
                                    this.tutorUsers = users;
                                    this.initialTutorUsers = JSON.parse(
                                        JSON.stringify(this.tutorUsers)
                                    );
                                    this.updateStructure();
                                    this.loadingService.stopLoading('userDetails', 'getTutorUsers');
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading('userDetails', 'getTutorUsers');
                                }
                            )
                        );
                        this.loadingService.startLoading('userDetails', 'getTutorGroups');
                        this.subscriptions.add(
                            this.groupService.getTutorGroups(this.user.id).subscribe(
                                (users: Array<User>) => {
                                    this.tutorGroups = users;
                                    this.initialTutorGroups = JSON.parse(
                                        JSON.stringify(this.tutorGroups)
                                    );
                                    this.updateStructure();
                                    this.loadingService.stopLoading(
                                        'userDetails',
                                        'getTutorGroups'
                                    );
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading(
                                        'userDetails',
                                        'getTutorGroups'
                                    );
                                }
                            )
                        );
                    } else {
                        this.updateStructure();
                    }
                    this.loadingService.stopLoading('userDetails', 'getStructures');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'getStructures');
                }
            )
        );
        this.loadingService.stopLoading('userDetails', 'getUser');
    }

    editTutorUsers() {
        return new Observable((observer) => {
            const arrayTutorUsers = [];
            this.initialTutorUsers.map((initialUser: User) => {
                if (this.tutorUsers.findIndex((user: User) => initialUser.id === user.id) === -1) {
                    arrayTutorUsers.push(
                        this.userService.deleteUserFromTutor(initialUser.id, this.user.id)
                    );
                }
            });
            this.tutorUsers.map((user: User) => {
                if (
                    this.initialTutorUsers.findIndex(
                        (initialUser: User) => user.id === initialUser.id
                    ) === -1
                ) {
                    arrayTutorUsers.push(this.userService.addUserToTutor(user.id, this.user.id));
                }
            });
            const arrayTutorGroups = [];
            this.initialTutorGroups.map((initialGroup: Group) => {
                if (
                    this.tutorGroups.findIndex((group: Group) => initialGroup.id === group.id) ===
                    -1
                ) {
                    arrayTutorGroups.push(
                        this.groupService.deleteGroupFromTutor(initialGroup.id, this.user.id)
                    );
                }
            });
            this.tutorGroups.map((group: Group) => {
                if (
                    this.initialTutorGroups.findIndex(
                        (initialGroup: User) => group.id === initialGroup.id
                    ) === -1
                ) {
                    arrayTutorGroups.push(
                        this.groupService.addGroupToTutor(group.id, this.user.id)
                    );
                }
            });

            if (arrayTutorUsers.length || arrayTutorGroups.length) {
                this.loadingService.startLoading('userDetails', 'editTutorUsers');
                this.subscriptions.add(
                    forkJoin(arrayTutorUsers.concat(arrayTutorGroups)).subscribe(
                        () => {
                            this.loadingService.stopLoading('userDetails', 'editTutorUsers');
                            this.initialTutorUsers = JSON.parse(JSON.stringify(this.tutorUsers));
                            this.initialTutorGroups = JSON.parse(JSON.stringify(this.tutorGroups));
                            observer.next();
                            observer.complete();
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('userDetails', 'editTutorUsers');
                        }
                    )
                );
            }
        });
    }

    editApplicationAccess() {
        const arrayApplicationsAccess = [];

        for (const i in this.applications) {
            if (
                !this.hasApplicationsAccess(this.applications[i]) &&
                this.applications[i].selected
            ) {
                arrayApplicationsAccess.push(
                    this.userService.addAccessToApplication(this.user, this.applications[i])
                );
            } else if (
                this.hasApplicationsAccess(this.applications[i]) &&
                !this.applications[i].selected
            ) {
                arrayApplicationsAccess.push(
                    this.userService.removeAccessToApplication(this.user, this.applications[i])
                );
            }
        }

        if (arrayApplicationsAccess.length > 0) {
            this.loadingService.startLoading('userDetails', 'editApplicationAccess');
            this.subscriptions.add(
                forkJoin(arrayApplicationsAccess).subscribe(
                    () => {
                        this.getApplicationAccess();
                        this.loadingService.stopLoading('userDetails', 'editApplicationAccess');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('userDetails', 'editApplicationAccess');
                    }
                )
            );
        }
    }

    canResetApplicationAccess() {
        return (
            (this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().additionalRoles.accountManager) &&
            this.user.id
        );
    }

    resetApplicationsAccess() {
        const arrayApplicationsAccess = [];

        for (const i in this.applications) {
            if (this.applications[i]) {
                arrayApplicationsAccess.push(
                    this.userService.resetAccessToApplication(this.user, this.applications[i])
                );
            }
        }

        this.loadingService.startLoading('userDetails', 'resetApplicationsAccess');
        this.subscriptions.add(
            forkJoin(arrayApplicationsAccess).subscribe(
                () => {
                    this.flashMessageService.flash(
                        'Les droits par défaut du rôle ont été réétablis'
                    );
                    this.getApplicationAccess();
                    this.loadingService.stopLoading('userDetails', 'resetApplicationsAccess');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'resetApplicationsAccess');
                }
            )
        );
    }

    editAdditionalRolesAccess() {
        const arrayAdditionalRolesAccess = [];

        for (const i in this.additionalRoles) {
            if (
                !this.hasAdditionalRoleAccess(this.additionalRoles[i]) &&
                this.additionalRoles[i].selected
            ) {
                arrayAdditionalRolesAccess.push(
                    this.userService.addAdditionalRole(this.user, this.additionalRoles[i])
                );
            } else if (
                this.hasAdditionalRoleAccess(this.additionalRoles[i]) &&
                !this.additionalRoles[i].selected
            ) {
                arrayAdditionalRolesAccess.push(
                    this.userService.removeAdditionalRole(this.user, this.additionalRoles[i])
                );
            }
        }

        if (arrayAdditionalRolesAccess.length > 0) {
            this.loadingService.startLoading('userDetails', 'editAdditionalRolesAccess');
            this.subscriptions.add(
                forkJoin(arrayAdditionalRolesAccess).subscribe(
                    () => {
                        this.getAdditionalRoleAccess();
                        this.loadingService.stopLoading('userDetails', 'editAdditionalRolesAccess');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('userDetails', 'editAdditionalRolesAccess');
                    }
                )
            );
        }
    }

    createUser() {
        if (!this.canCreateOrEditUser()) {
            return this.sites.filter((site) => site.selected).map((site) => site.key).length === 0;
        }
        this.formatUser();
        this.loadingService.startLoading('userDetails', 'createUser');
        this.subscriptions.add(
            this.userService.createUser(this.user).subscribe(
                (user: User) => {
                    this.user.id = user.id;
                    this.userId = user.id;
                    this.user.groups = user.groups;
                    this.user.sites = user.sites;
                    this.user.passwordtemp = user.passwordtemp;
                    this.flashMessageService.flash("L'utilisateur a été créé");
                    this.loadingService.stopLoading('userDetails', 'createUser');
                    if (this.user.roles.tutor) {
                        this.editTutorUsers().subscribe();
                    }
                    this.editApplicationAccess();
                    this.editAdditionalRolesAccess();
                    this.editionMode = false;
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'createUser');
                }
            )
        );
    }

    editUser() {
        if (!this.canCreateOrEditUser()) {
            return;
        }
        this.formatUser();
        this.loadingService.startLoading('userDetails', 'updateUser');
        this.subscriptions.add(
            this.userService.updateUser(this.user).subscribe(
                (user: User) => {
                    this.flashMessageService.flash("L'utilisateur a été modifié");
                    this.loadingService.stopLoading('userDetails', 'updateUser');
                    if (user.events) {
                        user = {
                            ...user,
                            cgu: {
                                value: user.events.CGU
                            },
                            emailverified: {
                                value: user.events.emailverified
                            }
                        };
                    }
                    if (this.user.roles.tutor) {
                        this.editTutorUsers().subscribe(() => {
                            this.formatUserData(user);
                        });
                    } else {
                        this.formatUserData(user);
                    }
                    this.editApplicationAccess();
                    this.editAdditionalRolesAccess();
                    this.editionMode = false;
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userDetails', 'updateUser');
                }
            )
        );
    }

    deleteUser() {
        this.emitDeleteUser.next(this.user);
    }

    toggleEditionMode() {
        this.editionMode = !this.editionMode;
    }

    isLoading(view?: string) {
        if (view) {
            return this.loadingService.isLoading('userDetails', view);
        } else {
            return this.loadingService.isLoading('userDetails');
        }
    }

    /**
     * Ferme la fenêtre de création de cloud
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
