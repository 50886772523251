import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Site } from '../structures/site';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class SiteService {
    constructor(private http: HttpClient) {}

    @log() getSites(structureid: number): Observable<Site[]> {
        const params: any = {
            structureid: structureid,
            isCorporation: false
        };

        return this.http.get<Site[]>(`/sites`, { params });
    }

    @log() getSite({ ...params }): Observable<Site> {
        const { siteid } = params;

        return this.http.get<Site>(`/sites/${siteid}`, { params });
    }

    @log() getCorporations(structureid: number): Observable<any> {
        const params: any = {
            structureid: structureid,
            isCorporation: true
        };

        return this.http.get(`/sites`, { params });
    }
}
