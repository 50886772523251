import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Application } from '../structures/application';
import { log } from './decorators/log.decorator';

/**
 *  service gerant l'utilisateur actuel dans l'application.
 */
@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    applications: Array<Application> = [];
    parentURL: string;

    activeApplicationSet: Subject<void> = new Subject();

    constructor(private http: HttpClient) {}

    /**
     *  l'application active.
     * @returns {any} l'application active.
     */
    getCurrentApplication(): any {
        const applications = Array.from(this.applications).reverse();
        for (const application of applications) {
            if (application.active === true) {
                return application;
            }
        }
    }

    getApplications(): Observable<Application[]> {
        if (this.applications.length) {
            return new Observable((observer) => {
                observer.next(this.applications);
                observer.complete();
            });
        } else {
            return this.getMyApplications();
        }
    }

    /**
     *  le tableau des applications via le web service prevu a cet effet.
     * @returns {Observable<any>} l'observable qui gere la recuperation de l'user courant.
     */
    @log() getMyApplications(): Observable<Application[]> {
        return this.http.get('/applications/access/my').pipe(
            tap({
                next: (data: any) => {
                    this.applications = data;
                }
            })
        );
    }

    /**
     *  le tableau des applications via le web service prevu a cet effet.
     * @returns {Observable<any>} l'observable qui gere la recuperation de l'user courant.
     */
    @log() getOurApplications(params: any): Observable<any> {
        return this.http.get('/applications/access/our', { params });
    }

    /**
     *  rafraichit l'app courante.
     * @returns {void}
     */
    setActiveApplications(url): void {
        for (const i in this.applications) {
            if (
                this.applications[i].frontend_url &&
                url.includes(this.applications[i].frontend_url)
            ) {
                this.applications[i].active = true;
            } else {
                this.applications[i].active = false;
            }
        }
        if (!this.getCurrentApplication()) {
            this.applications[0].active = true;
        }
        this.parentURL = url;
        this.activeApplicationSet.next();
    }

    getParentURL() {
        return this.parentURL;
    }
}
