<div
    class="notif-entry-container"
    [ngClass]="{ selected: isSelected(), unread: !isRead(), read: isRead() }"
    (click)="goToNotification()"
>
    <div class="header" [ngStyle]="{ color: getNotificationColor() }">
        <div class="icon" [ngClass]="getNotificationIcon()"></div>
        <div class="title">{{ notification.title }}</div>
        <div class="date" *ngIf="notification.date.toString() !== 'Invalid Date'">
            {{ notification.date | date: 'dd/MM/yyyy hh:mm' }}
        </div>
        <div class="bin-container">
            <span class="icon-bin" (click)="deleteNotification($event)"></span>
        </div>
    </div>
    <div class="body" [innerHTML]="getNotifBody()"></div>
</div>
<div class="separator"></div>
